import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => ({ getFilter, model_name }) => {
  const form_search_value = hooks.useFormValues()
  const navigate = hooks.useNavigate()
  const [, { openDialog }] = hooks.useDialogs()

  const current_path = hooks.usePathname()
  const [exportModel] = hooks.useModelFunction(model_name, 'export')
  const [active_user] = hooks.useActiveUser({ populate: ['info'] })

  const handleExport = useCallback(Callbacks.handleExportHandler(openDialog, model_name), [openDialog, model_name])
  const onSubmit = useCallback(Callbacks.onSubmitHandler(form_search_value, active_user, navigate, exportModel, getFilter), [form_search_value, navigate, exportModel, active_user, getFilter])

  const mergedProps = {
    current_path,
    model_name,
    onSubmit,
    handleExport
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
