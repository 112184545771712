import React, { useCallback } from 'react';
import TextComponent from '../Text'
import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();

import TypeUtils from '@front/volcanion/utils/type'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles';
import styles from './styles'
import _ from 'lodash';
import { withSelector } from '@front/volcanion'
import ViewSelector from '@front/volcanion/components/ViewSelector'
import MenuDecorator from '../../decorators/Menu'
const useStyles = makeStyles(styles)

import Callbacks from './callbacks'
import { Loader } from '../'

const AutocompleteFooter = React.memo(ViewSelector)
const AutocompleteLabelButtons = React.memo(ViewSelector)

const Autocomplete = (props) => {
  const {
    autoSelect,
    addressControls = {},
    displayEmpty,
    groupKey,
    name,
    value,
    onChange,
    options: inputOptions,
    multiple,
    selectOnFocus = !multiple,
    onInputChange,
    debounceTime = 250,
    isLoading,
    isReady,
    error,
    helperText,
    meta,
    inputRef,
    label,
    hiddenLabel,
    selectorProps,
    menuDecoratorProps = {},
    menuType = !!multiple ? 'checkbox' : 'standard',
    creatable: inputCreatable,
    createObject,
    selectCreatableLabel,
    freeSolo,
    renderInputProps,
    children,
    placeholder,
    disableUnderline,
    disabled,
    ...rest
  } = props
  const {
    getOptionKey,
    getOptionValue,
    getOptionLabel,
    isOptionEqualValue,
    isOptionEqualOption,
    getValueOption,
    getLabelKeys,
    getCreateKey,
    clearValue,
  } = selectorProps

  const {
    reset = _.noop
  } = addressControls

  const addressControlProps = {
    inputRef,
    ...addressControls
  }
  const createKey = getCreateKey() || _.head(getLabelKeys())
  const creatable = inputCreatable || !!getCreateKey()
  const classes = useStyles(props)
  const selectValue = useCallback(Callbacks.selectValueHandler(getOptionValue, getOptionLabel, selectCreatableLabel), [getOptionValue, getOptionLabel, selectCreatableLabel])
  const onSearch = useCallback(Callbacks.onSearchHandler(onInputChange, clearValue, multiple), [onInputChange, clearValue, multiple])
  const onSelect = useCallback(Callbacks.onSelectHandler(onChange, multiple, selectValue, reset), [onChange, multiple, selectValue, reset])
  const filterOptions = useCallback(Callbacks.filterOptionsHandler(filter, !!onInputChange, { creatable, createKey, createObject }), [filter, !!onInputChange, creatable, createKey, createObject])


  const autocompleteLabelChildren = TypeUtils.allByType(children, AutocompleteLabelButtons)
  const autocompleteFooterChildren = TypeUtils.allByType(children, AutocompleteFooter)

  const options = !selectCreatableLabel ? inputOptions : _.uniqBy(_.compact(_.flatten([inputOptions, !!value ? { [createKey]: value } : null])), createKey)
  const final_options = _.isEmpty(autocompleteFooterChildren) ? options : _.flatten([options, [{ label: 'footerAction', value: 'footerAction' }]])

  return (
    <Loader isLoading={isReady === false} size={30}>
      <MuiAutocomplete
        autoSelect={autoSelect}
        clearOnBlur
        clearOnEscape
        openOnFocus
        freeSolo={freeSolo}
        selectOnFocus={selectOnFocus}
        name={name}
        className={classes.root}
        value={value || ''}
        options={final_options || []}
        onChange={onSelect}
        multiple={multiple}
        renderInput={params => <TextComponent
          {...params}
          inputProps={{
            ..._.get(params, 'inputProps'),
            style: _.get(renderInputProps, 'inputProps.style')
          }}
          placeholder={placeholder}
          meta={meta}
          error={error}
          disabled={disabled}
          helperText={helperText}
          InputLabelProps={_.get(renderInputProps, 'InputLabelProps')}
          inputRef={inputRef}
          label={(!!label || !_.isEmpty(autocompleteLabelChildren)) && !hiddenLabel ? <Box sx={{ display: 'flex' }}>
            {label}
            {_.map(autocompleteLabelChildren, (autocompleteLabelChild, key) =>
              <ViewSelector selectorProps={selectorProps} {...addressControlProps} {...TypeUtils.getProps(autocompleteLabelChild)} key={key}>{autocompleteLabelChild}</ViewSelector>
            )}
          </Box> : undefined
          }
        />
        }
        renderOption={(optionProps, option) => (
          option?.value === 'footerAction'
            ? _.map(autocompleteFooterChildren, (autocompleteFooterChild, key) =>
              <ViewSelector option={option} selectorProps={selectorProps} {...addressControlProps}  {...TypeUtils.getProps(autocompleteFooterChild)} key={key} {...props}>{autocompleteFooterChild}</ViewSelector>
            )
            : <MenuDecorator
              {...menuDecoratorProps}
              {...optionProps}
              key={getOptionKey(option)}
              value={getOptionValue(option)}
              selectorProps={selectorProps}
              option={option}
              menuType={menuType}
            />
        )}
        getOptionLabel={option => _.isObject(option) || !!selectCreatableLabel ? getOptionLabel(option) || '' : getOptionLabel(getValueOption(option)) || ''}
        onInputChange={_.debounce(onSearch, debounceTime)}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, v) => _.isObject(v) ? isOptionEqualOption(option, v) : isOptionEqualValue(option, v)}
        disableCloseOnSelect={!!multiple}
        disabled={disabled}
        {...rest}
      />
    </Loader>
  );
}
const AutocompleteComponent = React.memo(Autocomplete)

export default React.memo(withSelector(AutocompleteComponent, { resetOnChange: true }))
export {
  AutocompleteLabelButtons,
  AutocompleteFooter
}
