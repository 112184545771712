import React from 'react'
import { Icon } from '@mui/material'

const IconComponent = ({ icon, ...props }) =>
  <Icon
    color={'primary'}
    baseClassName={'material-icons-outlined'}
    {...props}
  >
    {icon}
  </Icon>

export default React.memo(IconComponent)
