import _ from 'lodash'
class Callbacks {
  static onSelectHandler(selectedIds, setSelected) {
    return function onSelect(id) {
      const [existing_ids, other_ids] = _.partition(selectedIds, (selectedId) => selectedId === id)
      if (!_.isEmpty(existing_ids))
        setSelected(other_ids)
      else setSelected(_.flatten([other_ids, id]))
    }
  }
  static onSelectAllHandler(rowIds, selectedIds, setSelected) {
    return function onSelectAll(event) {
      if (event.target.checked)
        setSelected(_.uniq(_.flatten([selectedIds, rowIds])));
      else setSelected(_.filter(selectedIds, (id) => !_.includes(rowIds, id)));
    }
  }
}

export default Callbacks