import React from 'react';
import { Card, CardActionArea, CardContent as MuiCardContent, CardMedia, Typography, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles';
import styles from './styles'
import TypeUtils from '@front/volcanion/utils/type';
import ViewSelector from '@front/volcanion/components/ViewSelector'
const useStyles = makeStyles(styles)
const CardHeader = React.memo(ViewSelector)
const CardFooter = React.memo(ViewSelector)
const CardTooltip = React.memo(ViewSelector)
const CardContent = React.memo(ViewSelector)
const CardCustom = React.memo(ViewSelector)

const CardComponent = (props) => {
  const {
    value,
    title,
    subtitle,
    option,
    image,
    onChange,
    children,
    selectorProps,
    dense = false,
    titleProps,
    ...custom
  } = props

  const { isOptionSelected } = selectorProps
  const isSelected = isOptionSelected(option)
  const classes = useStyles(props)

  const cardCustomChildren = TypeUtils.allByType(children, CardCustom)
  const headerChildren = TypeUtils.allByType(children, CardHeader)
  const footerChildren = TypeUtils.allByType(children, CardFooter)
  const tooltipChildren = TypeUtils.allByType(children, CardTooltip)
  const contentChildren = TypeUtils.allByType(children, CardContent)

  const tooltipContent = <ViewSelector option={option} {...TypeUtils.getProps(tooltipChildren)}>{tooltipChildren}</ViewSelector>

  return (
    <>
      {_.isEmpty(cardCustomChildren) ?
        <Tooltip arrow followCursor title={tooltipContent}>
          <Card raised={!!isSelected} {...custom}>
            <CardActionArea onClick={() => onChange(value)} sx={{ backgroundColor: 'extraWhite' }}>
              <CardMedia image={image} alt={title} title={title}>
                {_.map(headerChildren, (headerChild, key) => <ViewSelector option={option} selectorProps={selectorProps} isSelected={isSelected}  {...TypeUtils.getProps(headerChild)} key={key}>{headerChild}</ViewSelector>)}
              </CardMedia>
              <MuiCardContent className={classes.content} sx={!!dense ? { padding: '3px !important' } : {}}>
                {_.map(contentChildren, (contentChild, key) => <ViewSelector option={option} selectorProps={selectorProps} isSelected={isSelected} {...TypeUtils.getProps(contentChild)} key={key}>{contentChild}</ViewSelector>)}
                {_.map(footerChildren, (footerChild, key) => <ViewSelector option={option} selectorProps={selectorProps} isSelected={isSelected}  {...TypeUtils.getProps(footerChild)} key={key}>{footerChild}</ViewSelector>)}
              </MuiCardContent>
            </CardActionArea>
          </Card >
        </Tooltip >
        : _.map(cardCustomChildren, (customChild, key) => <ViewSelector option={option} selectorProps={selectorProps} {...TypeUtils.getProps(customChild)} key={key} {...props}>{customChild}</ViewSelector>)
      }
    </>
  );
}
export default React.memo(CardComponent)
export {
  CardHeader,
  CardFooter,
  CardTooltip,
  CardContent,
  CardCustom
}
