import React from 'react'
import { MenuItem, ListItemText, Checkbox } from '@mui/material'

import CheckBoxIcon from '@mui/icons-material/CheckBox'

const CheckboxMenu = (props) => {
  const {
    option,
    selectorProps,
    createLabel = 'Add',
    ...rest
  } = props
  const { getOptionLabel, getOptionProps, isOptionSelected } = selectorProps
  const option_label = !!_.get(option, '_created') ? [createLabel, `"${getOptionLabel(option)}"`].join(' ') : getOptionLabel(option)
  return (
    <MenuItem {...getOptionProps(option)} {...rest}>
      <Checkbox checked={isOptionSelected(option)} checkedIcon={<CheckBoxIcon sx={{ color: 'icon.main' }} />} />
      <ListItemText primary={option_label} primaryTypographyProps={{ sx: { whiteSpace: 'initial' } }} />
    </MenuItem>
  )
};
export default React.memo(CheckboxMenu)
