import React from 'react'
import { Icon, Box, ListItemText, MenuItem } from '@mui/material'

const StandardMenu = (props) => {
  const {
    option,
    selectorProps,
    createLabel = 'Add',
    ...rest
  } = props
  const { getOptionValue, getOptionLabel, getOptionProps, getOptionIcon, getOptionIconProps } = selectorProps
  const option_label = !!_.get(option, '_created') ? [createLabel, `"${getOptionLabel(option)}"`].join(' ') : getOptionLabel(option)
  const icon = getOptionIcon(option)
  const { base = 'material-icons-outlined', size = 22, ...iconProps } = getOptionIconProps(option) || {}

  return (
    <MenuItem {...getOptionProps(option)} key={getOptionValue(option)} value={getOptionValue(option)} {...rest}>
      <Box sx={{ display: !!icon ? 'block' : 'none', mr: 1, fontSize: size }}>
        <Icon
          fontSize={'inherit'}
          color={'primary'}
          baseClassName={base}
          className={icon}
          {...iconProps}
        >
          {icon}
        </Icon>
      </Box>
      <ListItemText primary={option_label} primaryTypographyProps={{ sx: { whiteSpace: 'initial' } }} />
    </MenuItem>
  )
}

export default React.memo(StandardMenu)
