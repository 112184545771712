import React, { useMemo } from 'react';
import _ from 'lodash';
const withSingle = (Component) => ({ label, option, optionLabel, optionLabelPlacement = 'top', enabledValue = true, ...props }) => {
  const options = useMemo(() => [!!option ? option : { label: optionLabel, value: enabledValue, labelPlacement: optionLabelPlacement }], [option, optionLabel, enabledValue])
  return (
    <Component
      clearValue={enabledValue === true ? false : null}
      valueType={'switch'}
      label={label}
      fullWidth
      {...props}
      multiple={false}
      options={options}
    />
  );
}

export default withSingle
