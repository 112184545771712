import React from 'react'
import { TableAction } from '../..'
import { hooks } from '@front/volcanion'


const SaveRow = (props) => {
  const { action: { record_ids } } = props
  const isFormReadOnly = hooks.useFormStatus('isReadOnly')
  const [section_meta] = hooks.useLocalSectionMeta()
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  if (!!isFormReadOnly)
    return null
  if (!_.find(section_meta, ['dirty', true]))
    return null
  return (
    <TableAction
      label={"Save"}
      icon={'add'}
      {...props}
      onClick={() => requestSubmit({ record_ids })}
    />
  )
}

export default React.memo(SaveRow)