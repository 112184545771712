import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'
import { Box, Paper, MenuItem, MenuList, ClickAwayListener } from '@mui/material'

const ButtonList = ({
  anchorRef,
  setSelectedIndex,
  setOpen,
  options,
  ...props
}) => {

  const handleMenuItemClick = useCallback((event, index) => {
    setSelectedIndex(index)
    options[index].onClick()
    setOpen(false)
    event.stopPropagation()
  }, [setOpen], setSelectedIndex, options)

  const handleClose = useCallback(event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return
    setOpen(false)
    event.stopPropagation()
  }, [anchorRef, setOpen])

  return (
    <Paper>
      <ClickAwayListener onClickAway={handleClose}>
        <MenuList>
          {_.map(_.sortBy(options, ['index']), (option, index) => (
            <MenuItem
              key={index}
              disabled={typeof option.disabled === 'function' ? option.disabled() : option.disabled}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <Box fontWeight={600} color='text.subButton'> {I18n.t(option.labelKey)} </Box>
            </MenuItem>
          ))}
        </MenuList>
      </ClickAwayListener>
    </Paper>

  )
}

export default React.memo(ButtonList)
