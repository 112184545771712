import moment from 'moment'
class Memos {
  static getSanitized(value, allowInvalid, { input, output } = {}) {
    if (!!value) {
      if (!!_.get(value, '_isAMomentObject')) {
        if (value.isValid()) {
          if (!!output)
            return value.format(output)
          return value
        }
        if (allowInvalid)
          return value
        return null
      }
      return Memos.getSanitized(moment(value, input), allowInvalid, { input, output })
    }
    return value || null
  }
}

export default Memos
