import _ from 'lodash'
import LeafletListener from '../listener'
import Marker from './class'

class MarkerListener extends LeafletListener {
  generateLeafletInstance() {
    return new Marker(this, this.getActiveCoordinates(), _.merge({ autoPanOnFocus: false }, this.getOptions()))
  }
  isSinglePoint() {
    return true
  }
  onPositionChange(prev_position, new_position) {
    if (this.getOption('animate') && this.getParentLayer()?.hasLayer(this.getInstance()))
      this.getInstance().slideTo(new_position, { duration: this.getOption('slideDuration') || 1000 })
    else
      this.getInstance().setLatLng(new_position)
    return super.onPositionChange(prev_position, new_position)
  }
  onOptionsUpdated(event) {
    const props = event?.data
    const prevProps = event?.prev_value
    if (props.zIndexOffset != null && props.zIndexOffset !== prevProps.zIndexOffset)
      this.getInstance().setZIndexOffset(props.zIndexOffset);
    if (props.opacity != null && props.opacity !== prevProps.opacity)
      this.getInstance().setOpacity(props.opacity);
    if (this.getInstance().dragging != null && props.draggable !== prevProps.draggable) {
      if (props.draggable === true)
        this.getInstance().dragging.enable();
      else this.getInstance().dragging.disable();
    }
    return super.onOptionsUpdated(event)
  }
}

export default MarkerListener