import React from 'react'
import { TableAction } from '../..'
import { hooks } from '@front/volcanion'
import _ from 'lodash'


const SaveSelected = (props) => {
  const { action: { record_ids } } = props
  const isFormReadOnly = hooks.useFormStatus('isReadOnly')
  const [form_meta] = hooks.useFormMeta()
  const section_meta = _.filter(form_meta, (meta) => !!_.get(meta, 'dirty') && !!_.find(record_ids, (record_id) => _.startsWith(meta.field, record_id)))
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  if (!!isFormReadOnly)
    return null
  if (_.isEmpty(section_meta))
    return null
  return (
    <TableAction
      label={`Save Selected (${record_ids.length})`}
      icon={'add'}
      {...props}
      onClick={() => requestSubmit({ record_ids })}
    />
  )
}

export default React.memo(SaveSelected)