import L from 'leaflet'
class MaterialIcon extends L.Icon {
  createIcon() {
    var options = L.Util.setOptions(this);
    var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    var backgroundCircle = document.createElementNS('http://www.w3.org/2000/svg', "circle");
    var backgroundTriangle = document.createElementNS('http://www.w3.org/2000/svg', "polygon");
    var icongroup = document.createElementNS('http://www.w3.org/2000/svg', "g");
    var icon = document.createElementNS('http://www.w3.org/2000/svg', "text");
    const svgScale = 100 / options.iconSize[0]
    const circleScale = !_.isUndefined(options.circleScale) ? options.circleScale : Math.max(0.35 + 0.10 * svgScale, 0.5)
    const circleOutlineWidth = !_.isUndefined(options.circleOutlineWidth) ? options.circleOutlineWidth : 0.5
    const iconMargin = !_.isUndefined(options.iconMargin) ? options.iconMargin : 0.75
    svg.setAttribute('width', options.iconSize[0]);
    svg.setAttribute('height', options.iconSize[1]);
    svg.setAttribute('viewBox', `0 0 100 100`);
    svg.setAttribute('style', 'margin-left:-' + parseInt(options.iconSize[0] / 2) + 'px; margin-top:-' + options.iconSize[1] + 'px')
    svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");

    backgroundCircle.setAttribute('cx', '50%');
    backgroundCircle.setAttribute('cy', `${circleScale * 0.5 * 100}%`);
    backgroundCircle.setAttribute('r', `${circleScale * 0.5 * 100}%`);
    backgroundCircle.setAttribute('fill', options.circleColor || options.markerColor);
    backgroundCircle.setAttribute('stroke', options.circleOutlineColor || options.markerColor);
    backgroundCircle.setAttribute('stroke-width', circleOutlineWidth);
    var array = [
      [options.iconSize[0] * 0.5 * svgScale, options.iconSize[1] * svgScale],
      [(options.iconSize[0] * (1 + circleScale) * svgScale * 0.5), options.iconSize[1] * circleScale * svgScale * 0.5],
      [(options.iconSize[0] * (1 - circleScale) * svgScale * 0.5), options.iconSize[1] * circleScale * svgScale * 0.5],
    ];


    for (const value of array) {
      var point = svg.createSVGPoint();
      point.x = value[0];
      point.y = value[1];
      backgroundTriangle.points.appendItem(point);
    }
    backgroundTriangle.setAttribute('fill', options.markerColor);
    backgroundTriangle.setAttribute('stroke', options.outlineColor);
    backgroundTriangle.setAttribute('stroke-width', options.outlineWidth);

    icon.textContent = options.icon;
    icon.setAttribute('x', (1 - circleScale * iconMargin) * options.iconSize[0] * svgScale * 0.5);
    icon.setAttribute('y', (1 + iconMargin) * options.iconSize[1] * circleScale * svgScale * 0.5);
    icon.setAttribute('fill', options.iconColor);
    icon.setAttribute('font-family', 'Material Icons');
    icon.setAttribute('font-size', `${options.iconSize[0] * circleScale * iconMargin * svgScale}px`)


    svg.appendChild(backgroundTriangle);
    svg.appendChild(backgroundCircle);
    icongroup.appendChild(icon);
    svg.appendChild(icongroup);

    return svg;
  }
}

export default MaterialIcon