import _ from 'lodash'
class StorageManager {
  static async store(prefix, values) {
    if (!values)
      await localStorage.removeItem(_.compact(['@Fleetizen:storage', prefix]).join(':'))
    await localStorage.setItem(_.compact(['@Fleetizen:storage', prefix]).join(':'), JSON.stringify(values))
  }
  static async rehydrate(prefix) {
    const stored_values = await localStorage.getItem(_.compact(['@Fleetizen:storage', prefix]).join(':'))
    if (!!stored_values) {
      return JSON.parse(stored_values)
    }
    return null
  }
  static async reset(prefix) {
    await localStorage.removeItem(_.compact(['@Fleetizen:storage', prefix]).join(':'))
  }
}

export default StorageManager
