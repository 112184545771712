import LeafletListener from '../listener'
import Cluster from './class'


class ClusterListener extends LeafletListener {
  generateLeafletInstance() {
    return new Cluster(this, this.getOptions())
  }
  hasValidCoordinates() {
    return true
  }
}

export default ClusterListener