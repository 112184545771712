import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { I18n, hooks } from '@front/volcanion'

import TextComponent from '../../Text'
import Memos from '../memos'
import Callbacks from '../callbacks'

const TimeComponent = (props) => {
  const {
    value,
    onChange,
    onBlur,
    onFocus,
    helperText,
    error,
    isLoading,
    meta,
    inputFormat = "HH:mm",
    outputFormat = 'HH:mm',
    mask: inputMask,
    onKeyDown = _.noop,
    onError = _.noop,
    required,
    defaultDateTime = _.noop,
    ...custom
  } = props

  const locale = hooks.useLocale()
  const mask = useMemo(() => inputMask || _.map(inputFormat.split(':'), (segment) => _.fill(Array(segment.length), '_').join('')).join(':'), [inputMask, inputFormat])
  const sanitized_value = useMemo(() => Memos.getSanitized(value, true, { input: outputFormat }), [value, outputFormat])
  const [formatted_value, setFormatedValue] = useState(sanitized_value)
  const handleChange = useCallback(Callbacks.onChangeHandler(onChange, setFormatedValue, outputFormat), [onChange, outputFormat])
  const handleBlur = useCallback(Callbacks.onBlurHandler(onChange, onBlur, setFormatedValue, inputFormat, outputFormat, _.identity, defaultDateTime), [onChange, onBlur, inputFormat, outputFormat, defaultDateTime])

  useEffect(() => {
    if (moment.isMoment(sanitized_value))
      setFormatedValue(moment(sanitized_value, outputFormat).utc().isValid() ? moment(sanitized_value, outputFormat).utc().format() : moment(null, outputFormat).utc().format())
    if (sanitized_value == null && moment(formatted_value).isValid())
      setFormatedValue(null)
  }, [sanitized_value, outputFormat, formatted_value])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
      <TimePicker
        disablePast
        onChange={(e, keyboardValue) => (!!keyboardValue || !e?.isValid()) && handleChange(e)}
        onAccept={handleChange}
        value={formatted_value}
        inputFormat={inputFormat}
        mask={mask}
        minTime={null}
        {...custom}
        error={error}
        helperText={helperText}
        onError={err =>
          !!err ? onError(I18n.t(`sqr.time.${err}`)) : onError(null)
        }
        renderInput={(params) => (
          <TextComponent
            {...params}
            meta={meta}
            helperText={helperText}
            error={error}
            emptyValue={''}
            onBlur={handleBlur}
            onFocus={onFocus}
            required={required}
            onKeyDown={e => onKeyDown(e, { onChange: handleChange })}
          />
        )}
        OpenPickerButtonProps={{ tabIndex: -1, id: 'timePickerIcon', sx: { color: 'icon.main' } }}
      />
    </LocalizationProvider>
  )
}

export default React.memo(TimeComponent)
