import { useCallback } from "react"
import Callbacks from './callbacks'
import { useSnackbar } from 'notistack'
const useNotification = () => {
  const snackbar = useSnackbar()
  const open = useCallback(Callbacks.openNotificationHandler(snackbar), [snackbar])
  const close = useCallback(Callbacks.closeNotificationHandler(snackbar), [snackbar])
  return {
    open,
    close
  }
}

export default useNotification