import React from 'react';

import { InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { defaultCountries as inputCountries, FlagImage, parseCountry, usePhoneInput, } from 'react-international-phone';

import 'react-international-phone/style.css';

const TextPhoneInputComponent = ({
  value,
  onChange,
  label,
  disabled,
  InputProps,
  defaultCountry,
  availableCountries,
  ...restProps
}) => {
  const mergerInputProps = _.merge({}, { disableUnderline: !!disabled }, InputProps)

  const defaultCountries = _.filter(inputCountries, inputCountry => {
    const [, shortInputCountry] = inputCountry
    return _.isEmpty(availableCountries) ? true : _.includes(availableCountries, shortInputCountry)
  })

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: _.toLower(defaultCountry),
    value: value || '',
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone);
    },
  })

  return (
    <TextField
      label={label}
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      fullWidth
      inputRef={inputRef}
      disabled={disabled}
      InputProps={{
        ...mergerInputProps,
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: '2px', marginLeft: '-8px' }}
          >
            <Select
              disabled={disabled}
              MenuProps={{
                style: {
                  height: '300px',
                  width: '360px',
                  top: '10px',
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',
                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(renderValue) => (
                <FlagImage iso2={renderValue} style={{ display: 'flex' }} />
              )}
            >
              {defaultCountries.map((c) => {
                const countryItem = parseCountry(c);
                return (
                  <MenuItem key={countryItem.iso2} value={countryItem.iso2}>
                    <FlagImage
                      iso2={countryItem.iso2}
                      style={{ marginRight: '8px' }}
                    />
                    <Typography marginRight="8px">{countryItem.name}</Typography>
                    <Typography color="gray">+{countryItem.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  )
}
export default TextPhoneInputComponent