
const creator = (parts, part) => part(parts);
const extender = (Base, ...parts) => parts.reduce(creator, Base);
function ListenerBridge(Base) {
  return class Bridge extends Base {
    constructor(listener, ...args) {
      super(...args)
      this._listener = listener
    }
    getId() {
      return this.getParentListener().getId()
    }
    getParentProvider() {
      return this.getParentListener().getParentProvider()
    }
    getParentListener() {
      return this._listener
    }
    getMapListener() {
      return this.getParentListener().getParentMap().getParentListener()
    }
    notifyCallbacks(event) {
      return this.getParentListener()?.notifyCallbacks(event)
    }
    getStyles() {
      const parent_style = this.getParentListener()?.getParentLayer()?.getStyles() || {}
      const customGetStyles = this.getParentListener()?.getOption('getStyles') || _.noop
      return _.merge({}, parent_style, this.getParentListener().getOption('styles'), customGetStyles(this, this.getParentListener()?.getParentListener()?.getResult()))
    }
    getStyle(name) {
      return _.get(this.getStyles(), name)
    }
    getDefaultStyles() {
      return {}
    }
    refreshStyles(prev_styles, new_styles) {
      if (this.getParentListener().getOption('listener_type') === 'layer' || this.getParentListener().getOption('listener_type') === 'cluster')
        _.map(this.getLayers(), (layer) => layer?.refreshStyles(prev_styles, new_styles))
      return this
    }
    getControlOptions() {
      return this.getParentListener().getControlOptions()
    }
    getControlName() {
      return this.getParentListener().getControlName()
    }
    getControlGroup() {
      return this.getParentListener().getControlGroup()
    }
    isControlDefaultEnabled() {
      return this.getParentListener().isControlDefaultEnabled()
    }
    isControlEnabled() {
      return this.getParentListener().isControlEnabled()
    }
    isControlled() {
      return !!this.getParentListener().isControlled()
    }
    addToParent() {
      return this.getParentListener().addToParent()
    }
    removeFromParent() {
      return this.getParentListener().removeFromParent()
    }
  }
}
const ExtendBridge = (base) => extender(base, ListenerBridge)

export default ListenerBridge
export { extender, ExtendBridge }