import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import OrderUtils from '@front/volcanion/utils/order'

const withOrder = Component => ({ record_id, onClick: onInputClick, sx: _sx = {}, ...rest }) => {
  const [order] = hooks.useModel('order', [record_id], {
    populate: ['do.auth.application', 'commercial_package'],
    single: true
  })

  const type = OrderUtils.getOrderType(_.get(order, 'do.auth.application.name'), _.get(order, 'commercial_package.load_type'))

  const sx = {
    color: OrderUtils.getColorTheme(type),
    ..._sx
  }

  const onClick = useCallback(e => {
    !!onInputClick && onInputClick(e)
    !onInputClick && window.open(`/order/${record_id}`, '_blank')
    e.stopPropagation()
  }, [onInputClick, record_id])

  const label = order?.order_client_id

  const mergedProps = {
    label,
    onClick,
    sx,
    ...rest
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withOrder
