import L from 'leaflet'
import MaterialIcon from '../MaterialIcon'
import _ from 'lodash'
import ListenerBridge from '../bridge'

class Marker extends ListenerBridge(L.Marker) {
  getDefaultStyles() {
    return {
      className: 'l-icon-material',
      markerColor: this.getStyle('color'),
      iconColor: 'white',
      circleOutlineColor: 'white',
      circleOutlineWidth: 1,
      outlineColor: 'gray',
      outlineWidth: 0.5,
      iconSize: [48, 48]
    }
  }
  refreshStyles(prev_styles, new_styles) {
    const styles = _.merge({}, this.getDefaultStyles(), this.getStyles())
    const final_styles = _.merge({ popupAnchor: [0, -styles.iconSize[1]] }, styles)
    this.setIcon(new MaterialIcon(final_styles))
    return super.refreshStyles(prev_styles, new_styles)
  }
}

export default Marker