import React from 'react'
import I18n from '@front/volcanion/utils/translation'

import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import moment from 'moment'

import FRFlag from '@front/squirtle/assets/flag/fr.svg'
import GBFlag from '@front/squirtle/assets/flag/gb.svg'

class FormatUtils {
  static getStatus = (values = {}) => {
    const { active, deleted, suspendedUntil, suspendedSince } = values
    const isDateBetween = (sinceDate, untilDate) => {
      if (!sinceDate && !untilDate) return false
      if (!untilDate) return moment().isAfter(sinceDate)
      if (!sinceDate) return moment().isBefore(untilDate)
      return moment().isBetween(sinceDate, untilDate)
    }

    if (!!deleted) return 'deleted'
    else if (!suspendedSince && !suspendedUntil && !active) return 'inactive'
    else if (isDateBetween(suspendedSince, suspendedUntil) || active === false) return 'suspended'
    else return 'active'
  }

  static parseStatus = (status, suspension = {}) => {
    const { suspendedUntil, suspendedSince, motive } = suspension
    return !!_.includes(['suspended', 'active'], status)
      ? {
        name: status,
        motive,
        suspendedUntil,
        suspendedSince,
      }
      : { name: status }
  }

  static getStatusColor = status => {
    switch (status) {
      case 'active': return 'green'
      case 'archived':
      case 'suspended':
      case 'inactive': return 'orange'
      case 'deleted': return 'red'
      default: return 'black'
    }
  }

  static getFlagIcon = lang => {
    switch (lang) {
      case 'fr':
        return <Icon component={FRFlag} />
      case 'nl':
        return <Box sx={{ width: '24px', height: '16px' }}>
          <Box height={1 / 3} bgcolor='red' />
          <Box height={1 / 3} bgcolor='extraWhite' />
          <Box height={1 / 3} bgcolor='blue' />
        </Box>
      case 'en':
        return <Icon component={GBFlag} />
      default:
        return 'None'
    }
  }
  static distance = value => !!value ? `${(value / 1000).toFixed(2)} km` : null

  static formatHistoryExtra = (action, driverOptions, vehicleOptions) => {
    switch (action.name) {
      case 'driver_suspended':
      case 'license_suspended':
        try {
          const infos = JSON.parse(action.infos)
          if (!infos) return
          return `${FormatUtils.formatDateBackToFront(infos.since)} - ${FormatUtils.formatDateBackToFront(infos.until)} : ${infos.motive}`
        }
        catch (err) {
          return action.infos
        }
      case 'driver_options':
        return _.join(_.compact(_.map(_.split(action.infos, ','), user_option_id => {
          const currentOption = _.head(_.filter(driverOptions, option => option.user_option_id === user_option_id))
          return _.get(currentOption, 'name_translated', null)
        })), ', ')
      case 'vehicle_options':
        return _.join(_.compact(_.map(_.split(action.infos, ','), vehicle_option_id => {
          const currentOption = _.head(_.filter(vehicleOptions, option => option.vehicle_option_id === vehicle_option_id))
          return _.get(currentOption, 'name_translated', null)
        })), ', ')
      default: return action.infos;
    }
  }

  static formatSuspended = (status, type, context) => {
    const { name, suspendedSince, suspendedUntil, motive = I18n.t('no_motive') } = status
    const reason = !!motive ? motive : I18n.t('no_motive')

    if (name === 'suspended') {
      if (!!suspendedUntil && !!suspendedSince) {
        return I18n.t(`suspend.${type}.suspended_since_until`, {
          suspendedUntil: FormatUtils.formatDateBackToFront(suspendedUntil),
          suspendedSince: FormatUtils.formatDateBackToFront(suspendedSince),
          motive: reason
        })
      }
      else if (!!suspendedUntil) return I18n.t(`suspend.${type}.suspended_until`, {
        motive: reason,
        suspendedUntil: FormatUtils.formatDateBackToFront(suspendedUntil)
      })
      else if (!!suspendedSince) return I18n.t(`suspend.${type}.suspended_since`, {
        motive: reason,
        suspendedSince: FormatUtils.formatDateBackToFront(suspendedSince)
      })
      else return I18n.t(`suspend.${type}.suspended`, {
        motive: reason
      })
    } else if (name === 'active' && moment(suspendedSince).isAfter() || moment(suspendedUntil).isAfter()) {
      if (!!suspendedUntil && !!suspendedSince) {
        return I18n.t(`suspend.${type}.suspended_futur_since_until`, {
          suspendedUntil: FormatUtils.formatDateBackToFront(suspendedUntil),
          suspendedSince: FormatUtils.formatDateBackToFront(suspendedSince),
          motive: reason
        })
      }
      else
        return I18n.t(`suspend.${type}.suspended_futur_since`, {
          suspendedSince: FormatUtils.formatDateBackToFront(suspendedSince),
          motive: reason
        })
    } else return null
  }

  static formatDateFrontToBack = date => (!_.isEmpty(date)
    ? moment(date)
    : moment()
  ).utc().toISOString()

  static formatDateBackToFront = (date, format, defaultValue) =>
    _.isEmpty(date)
      ? defaultValue
      : moment.utc(date).local().format(format || 'DD/MM/YYYY HH:mm')

  static formatEventData = eventData => {
    if (_.isEmpty(eventData)) return []
    const result = []
    for (var key in eventData) {
      const dataKey = eventData[key]
      const data = moment(dataKey).isValid() ? FormatUtils.formatDateBackToFront(dataKey) : dataKey
      result.push(`${key}: ${data}`)
    }
    return result
  }

  static parsePhoneNumber = (value, codeIntlPhone) =>
    (value || '').replace(/([ .-]+)/g, '')
      .replace(/^00/g, '+')
      .replace(/^0([1-9])/g, `${codeIntlPhone}$1`)


  static parseFMBPhoneNumber = (value, codeIntlPhone) =>
    (value || '').replace(/([ .-]+)/g, '')
      .replace(/^00/g, '+')
      .replace(/^0([1-9])/g, `+${codeIntlPhone}$1`)

  static intervalFilter = (start, end) => {
    if (!!start && !!end) return { '>=': start, '<=': end }
    else if (!!start && !end) return { '>=': start }
    else if (!start && !!end) return { '<=': end }
    else return undefined
  }

}

export default FormatUtils
