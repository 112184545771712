import React from 'react'
import CardGridComponent from './component'
import { withField, withModel, withAddress } from '@front/volcanion'
const ModelCardGridComponent = React.memo(withModel(CardGridComponent))
const AddressCardGridComponent = React.memo(withAddress(CardGridComponent))
const CardGridField = React.memo(withField(CardGridComponent))
const ModelCardGridField = React.memo(withModel(CardGridField))
const AddressCardGridField = React.memo(withAddress(CardGridField))

export {
  CardGridComponent,
  ModelCardGridComponent,
  AddressCardGridComponent,
  CardGridField,
  ModelCardGridField,
  AddressCardGridField
}
export default CardGridComponent
