

import {
  colors,
  buildTheme,
  /* DEPRECATED */
  setFleetizenLayerTheme,
  MuiThemeV3,
  MuiThemeV3_DARK
} from '@front/squirtle/themes'

import { createTheme } from '@mui/material/styles'
class IdentityUtils {
  /* DEPRECATED */
  static getThemeIdentity = (identity, mode) => {
    const isDark = mode === 'dark'
    const MuiThemeDefault = _.merge({}, MuiThemeV3, isDark ? MuiThemeV3_DARK : {})

    switch (identity) {
      case 'HP':
        return createTheme(_.merge({}, MuiThemeDefault, setFleetizenLayerTheme({ light: '#b3ddff', primary: !isDark ? colors['ocean-blue'] : '#6573cb' }, isDark)))
      case 'TV':
        return createTheme(_.merge({}, MuiThemeDefault, setFleetizenLayerTheme({ light: 'rgba(6, 142, 51, 0.1)', primary: !isDark ? colors['jungle-green'] : '#6ba475' }, isDark)))
      case 'AP':
        return createTheme(_.merge({}, MuiThemeDefault, setFleetizenLayerTheme({ light: '#fff9ce', primary: !isDark ? colors['yellow'] : '#fff45c' }, isDark)))
      case 'FL':
      default:
        return createTheme(_.merge({}, MuiThemeDefault, setFleetizenLayerTheme({ light: '#9fa4c0', primary: !isDark ? colors['darks-late-gray'] : '#696d8b' }, isDark)))
    }
  }

  static getTheme = (identity, mode) => {

    const getLightColor = () => {
      switch (identity) {
        case 'HP':
          return colors['ocean-blue']
        case 'TV':
          return colors['jungle-green']
        case 'AP':
          return colors['yellow']
        case 'FL':
        default:
          return colors['darks-late-gray']
      }
    }
    const getDarkColor = () => {
      switch (identity) {
        case 'HP':
          return colors['ocean-blue-light']
        case 'TV':
          return colors['jungle-green-light']
        case 'AP':
          return colors['yellow-light']
        case 'FL':
        default:
          return colors['darks-late-gray-light']
      }
    }

    const light = getLightColor()
    const dark = getDarkColor()

    return createTheme(buildTheme({ main: mode === 'light' ? light : dark, light, dark }, mode))
  }
}
export default IdentityUtils
