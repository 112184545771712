import React from 'react'
import { Grid, Typography, Box } from '@mui/material';
import Card from '../Card';

import Loader from '../Loader'
import { withSelector } from '@front/volcanion'

const CardGrid = (props) => {
  const {
    value,
    onChange,
    options,
    colCount = 3,
    disabled,
    readOnly,
    multiple,
    cardProps = {},
    isLoading,
    children,
    selectorProps,
    error,
    helperText,
    inputRef,
    onInputChange,
    cardLabelProps = {},
    displayEmpty,
    loaderProps,
    isReady,
    ...custom
  } = props
  const {
    getOptionLabel,
    getOptionValue,
    getOptionKey,
    getOptionProps,
    getOptionsProps
  } = selectorProps

  const { hide } = getOptionsProps() || {}


  return (<Box hidden={hide}>
    <Loader isLoading={isLoading} {...loaderProps}>
      <Grid container spacing={1} alignItems='center' {...custom}>
        {
          _.map(options, (option) => (
            <Grid xs={12 / colCount} item key={getOptionKey(option)}>
              <Card
                {...cardProps}
                {...getOptionProps(option)}
                title={<Typography {...cardLabelProps}>{getOptionLabel(option)}</Typography>}
                value={getOptionValue(option)}
                onChange={onChange}
                option={option}
                selectorProps={selectorProps}
              >
                {children}
              </Card>
            </Grid>
          ))
        }
        {!!error && <Typography value={helperText} color={'red'} />}
      </Grid>
    </Loader>
  </Box>
  );
}



export default React.memo(withSelector(CardGrid, { allowDeselect: false }))
