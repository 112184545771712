import React from 'react'

import Link from './component'

import withDriver from './Driver'
import withLicense from './License'
import withOrder from './Order'
import withVehicle from './Vehicle'
import withUser from './User'

const DriverLink = React.memo(withDriver(Link))
const LicenseLink = React.memo(withLicense(Link))
const OrderLink = React.memo(withOrder(Link))
const VehicleLink = React.memo(withVehicle(Link))
const UserLink = React.memo(withUser(Link))

export { DriverLink, LicenseLink, OrderLink, VehicleLink, UserLink }
