import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ name, onClose: inputOnClose = _.noop, forceDialogAction = true, ...props }) => {
  const [{ isOpen, extra, state }, { closeDialog }] = hooks.useDialog(name)

  const onClose = useCallback((event, reason) => {
    if (!_.includes(['escapeKeyDown', 'backdropClick'], reason) || !forceDialogAction) {
      inputOnClose(state, event, reason)
      closeDialog(name)
    }
  }, [name, inputOnClose, state, forceDialogAction])

  const mergedProps = {
    name,
    extra,
    state,
    isOpen,
    onClose,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
