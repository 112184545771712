import React from 'react'

const Image = ({ path, style, ...rest }) => {
  return (
    <img src={path} style={{ width: '100%', height: '100%', ...style }} {...rest} />
  )
}


export default Image
export { Image }