import React from 'react'
import { TableAction } from '../..'
import { hooks } from '@front/volcanion'


const AddRow = ({ getEmptyForm = () => ({}), ...props }) => {
  const addSection = hooks.useFormFunction('addSection')
  const isFormReadOnly = hooks.useFormStatus('isReadOnly')
  if (!!isFormReadOnly)
    return null
  return (
    <TableAction
      label={"Add New"}
      icon={'add'}
      {...props}
      onClick={() => addSection('_create', getEmptyForm())}
    />
  )
}

export default React.memo(AddRow)