import React from 'react'
import VerificationInput from 'react-verification-input'

import { makeStyles } from '@mui/styles'
import { FormHelperText } from '@mui/material'

import stylesJS from './styles'

const useStyles = makeStyles(stylesJS)


const PhoneVerification = props => {
  const {
    disabled,
    label,
    onChange,
    helperText,
    error,
    ...rest
  } = props
  const classes = useStyles(props)
  return <>
    <VerificationInput
      autoComplete='off'
      onChange={onChange}
      length={4}
      validChars='0-9'
      removeDefaultStyles={true}
      autoFocus
      classNames={{
        container: classes.container,
        character: classes.character,
        characterInactive: classes.characterInactive,
        characterSelected: classes.characterSelected,
      }}
      {...rest}
    />
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </>
}

export default PhoneVerification