import LeafletListener from '../listener'
import Tooltip from './class'

class TooltipListener extends LeafletListener {
  generateLeafletInstance() {
    return new Tooltip(this, this.getActiveCoordinates(), this.getOptions())
  }
  isSinglePoint() {
    return true
  }
  onPositionChange(prev_position, new_position) {
    this.getInstance().setLatLng(new_position)
    return super.onPositionChange(prev_position, new_position)
  }
  addToParent() {
    this.getParentOverlay()?.bindTooltip(this.getInstance())
    return this.getParentControl()?.notifyCallbacks({ name: 'parent_add', data: { source: this, parent: this.getParentOverlay() } })
  }
  removeFromParent() {
    this.getParentOverlay()?.unbindTooltip()
    this.getParentMap()?.removeLayer(this.getInstance());
    this.getInstance()?.remove()
    return this.getParentControl()?.notifyCallbacks({ name: 'parent_remove', data: { source: this, parent: this.getParentOverlay() } })
  }
}

export default TooltipListener