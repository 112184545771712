/* DEPRECATED */
import colors from './colors'

const setFleetizenLayerTheme = (customColor, isDark, overrides) => (
  !!isDark
    ? {
      palette: {
        primary: {
          main: customColor.primary,
        },
        icon: {
          selected: colors['background-dark-primary'],
          default: colors['white']
        },
        background: {
          card: customColor.primary,
          menu: customColor.primary
        }
      }
    }
    : {
      palette: {
        primary: {
          main: customColor.primary,
          light: customColor.light
        },
        icon: {
          selected: customColor.light,
          default: colors['gray-dark']
        },
        text: {
          button: {
            outlined: customColor.primary
          }
        }
      },
      overrides: {
        MuiAutocomplete: {
          option: {
            '&[data-focus="true"]': {
              backgroundColor: colors["gray-light"],
            },
          },
        }
      }
    }
)

export default setFleetizenLayerTheme

