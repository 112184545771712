import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withLicense = Component => ({ record_id, onClick: onInputClick, ...rest }) => {
  const [vehicle] = hooks.useModel('vehicle', [record_id], {
    single: true,
    populate: ['info.model.brand']
  })

  const onClick = useCallback(e => {
    !!onInputClick && onInputClick(e)
    const href = !!vehicle?.group ? `/adherent/${vehicle?.group}/vehicle/${record_id}` : `/vehicle/${record_id}`
    !onInputClick && window.open(href, '_blank')
    e.stopPropagation()
  }, [onInputClick, record_id, vehicle])

  const label = _.join(_.compact([
    vehicle?.info?.plaque,
    _.join(_.compact([
      vehicle?.info?.model?.name,
      vehicle?.info?.model?.brand?.name,
      vehicle?.info?.color
    ]), ' ')]
  ), ' - ')

  const mergedProps = {
    label,
    onClick,
    ...rest
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withLicense
