/* Do not use outsite Material theme */
const colors = {

  "black": "#000000",
  "white": "#F1F1F1",
  "extraWhite": "#FFFFFF",
  "white-dark": "#bebebe",
  "vermillion": "#E21818",

  "gray": "#D4D4D4",
  "gray-dark": "#383838",
  "gray-mid-light": "#00000061",
  "gray-light": "#E2E2E2",
  "gray-background": "#D8D8d8",
  "gray-extra-light": "#DFDFDFDF",

  "deep-blue": "#0060AC",
  "vivid-orange": "#E59400",

  "gray-background-light": "#F1F1F1",

  "background-dark": "#383838",
  "background-light": "#e2e2e2",

  /* DEPRECATED */
  "background-dark-primary": "#383838",
  "background-default-primary": "#e2e2e2",

  "green": "#4CAF50",
  "pink": "#EF5350",

  // Hype
  "ocean-blue": "#1248C3",
  "ocean-blue-light": '#3796D0',
  // Taxi vert
  "jungle-green": "#068E33",
  "jungle-green-light": "#50af70",
  // Fleetizen
  "darks-late-gray": '#3d425e',
  "darks-late-gray-light": '#9fa4c0',
  // Alpha
  "yellow": '#eec223',
  "yellow-light": '#ffff96',

  "secondary": "#ef7d63"

}

export default colors
