import { useContext } from 'react';
import { LeafletContext } from '../../MapFactory/Map/component'

const useLeafletContext = () => {
  const context = useContext(LeafletContext);
  if (context == null) {
    throw new Error('No context provided: useLeafletContext() can only be used in a descendant of <MapContainer>');
  }
  return context;
}

export default useLeafletContext