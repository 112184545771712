import React, { useMemo } from 'react'
import { useTheme } from '@mui/styles'

import { Chip, CircularProgress } from '@mui/material'

const ChipOption = (props) => {
  const {
    value,
    selectorProps,
    ...rest
  } = props
  const {
    getOptionLabel,
    getOptionKey,
    getOptionProps,
    getValueOption,
    isOptionLoading
  } = selectorProps
  const theme = useTheme()
  const matched_option = getValueOption(value)
  const optionsProps = getOptionProps(matched_option)

  const textColor = useMemo(() => {
    switch (optionsProps?.color) {
      case 'warning': return theme?.palette?.orange
      case 'success': return 'green'
      default: return theme?.palette?.primary?.main
    }
  }, [optionsProps?.color])

  return (
    <Chip
      size='small'
      variant="outlined"
      color={'primary'}
      sx={{
        WebkitTextFillColor: textColor,
        fontWeight: 600,
        opacity: 0.8,
        margin: '1px'
      }}
      {...optionsProps}
      key={getOptionKey(matched_option)}
      label={getOptionLabel(matched_option)}
      icon={isOptionLoading(matched_option) ? <CircularProgress size={'0.75rem'} /> : undefined}
      {...rest}
    />
  )
}

export default React.memo(ChipOption)
