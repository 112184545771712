import useLeafletContext from './useLeafletContext'
import useAttachedListener from './useAttachedListener'
const useLayersControlListener = (callback) => {
  const context = useLeafletContext()
  return useAttachedListener(
    context?.layersControl?.getId(),
    'control',
    callback
  )
}

export default useLayersControlListener