import React, { useCallback, useEffect, useSyncExternalStore, createContext } from 'react'
import MapProvider from './provider'
import BaseLayer from '../Base'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '@front/volcanion/provider'
import { Loader } from '../../components';
import { useProviderListener } from '@front/volcanion/hooks';
const LeafletContext = createContext(null);
const LeafletProvider = LeafletContext.Provider;

function MapContainer({ children, className, mapId, style, ...options }) {
  const isProviderReady = useSyncExternalStore(
    useCallback(ProviderInstance.prepareDynamicProvider(new MapProvider(mapId)), [mapId]),
    useCallback(() => ProviderInstance.getProviderStatus(mapId), [])
  )

  const [context, { isReady }] = useSyncExternalStoreWithSelector(
    useProviderListener(mapId, mapId, {}, options, [isProviderReady, options], [], []),
    useCallback(() => [
      ProviderInstance.getProvider(mapId)?.getListener(mapId)?.getLeafletContext(),
      ProviderInstance.getProvider(mapId)?.getFullListenerStatus(mapId) || {}
    ], [mapId]),
    null,
    _.identity,
    _.isEqual
  )

  useEffect(() => () => ProviderInstance.removeProvider(mapId), [])
  return (
    <div id={mapId} style={style} className={className}>
      <Loader isLoading={!isProviderReady || !isReady}>
        <LeafletProvider value={context}>
          {children}
        </LeafletProvider>
      </Loader>
    </div>
  )
}

const MapCanvas = (props) => {
  const {
    mapId,
    mapStyle,
    className,
    mapCenter,
    zoom = 12,
    eventHandlers,
    children,
    gestureHandling
  } = props
  return (
    <MapContainer
      center={mapCenter}
      zoom={zoom}
      mapId={mapId}
      className={className}
      style={mapStyle}
      zoomControl={false}
      eventHandlers={eventHandlers}
      gestureHandling={gestureHandling}
    >
      <BaseLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        subdomains='abcd'
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      />
      {children}
    </MapContainer >
  )
}

export default React.memo(MapCanvas)
export { LeafletContext, LeafletProvider }

