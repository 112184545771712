/* DEPRECATED */
import { createTheme } from '@mui/material/styles'
import colors from './colors'

const black = colors['black']
const white = colors['white']
const grayDark = colors["gray-dark"]
const grayLight = colors["gray-light"]

const light = createTheme({
  typography: {
    fontFamily: [
      `"Montserrat", sans-serif`,
    ].join(','),
  },
  palette: {
    icon: {
      default: grayDark
    },
    secondary: {
      main: '#ef7d63'
    },
    text: {
      title: grayDark,
      disabled: black,
      label: grayDark,
      sub: grayLight,
      input: black,
      button: {
        default: white
      },
      subButton: black,
      menu: {
        title: white,
        text: black,
        icon: '',
      }
    },
    background: {
      secondary: colors['extraWhite'],
      card: '',
      menu: white
    },
    ardornment: grayDark,
    black: black,
    vermillion: colors['vermillion'],
    white: white,
    extraWhite: colors['extraWhite'],
    packageText: colors['vivid-orange'],
    orange: colors['vivid-orange'],
    collectoText: colors['deep-blue'],
    blue: colors['deep-blue'],
    greyishBrown: grayDark,
    greyishBrownTwo: grayDark,
    greyishBrownThree: grayDark,
    grayDark: grayDark,
    grayLight: colors["gray-light"],
    grayExtraLight: colors['gray-extra-light'],
    grayBackground: colors['gray-background'],
    grayBackgroundLight: white,
    green: colors['green'],
    pink: colors['pink'],
    grayMidLight: colors['gray-mid-light'],
    status: {
      planned: colors['vivid-orange'],
      created: colors['vivid-orange'],
      standby: colors['vivid-orange'],
      started: colors['deep-blue'],
      at_src: colors['deep-blue'],
      on_board: colors['deep-blue'],
      at_dst: colors['deep-blue'],
      off_board: colors['deep-blue'],
      attributed: colors['deep-blue'],
      canceled: colors['vermillion'],
      complete: colors['green']
    }
  },
  components: {
    MuiSwitch: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
      }
    },
    MuiInput: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: grayDark
          }
        }
      },
      defaultProps: {
        shrink: true,
        size: 'small'
      }
    },
    MuiTextField: {
      defaultProps: {

        variant: "standard"
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
        size: 'small',
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: grayDark
          }
        }
      },
      defaultProps: {
        variant: "standard",
        size: 'small',
      }
    }
  }
})

const dark = {

}

export {
  light,
  dark
}
