const styles = (theme) => {
  return {
    dropdown: {
      color: 'black'
    },
    container: {
      padding: 5
    }
  }
}

export default styles
