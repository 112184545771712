import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from '@mui/material'
import { makeStyles } from '@mui/styles';

import styles from './styles'
import TypeUtils from '@front/volcanion/utils/type';
import ViewSelector from '@front/volcanion/components/ViewSelector';
import HookUtils from '@front/volcanion/utils/hooks';
const Row = React.memo(ViewSelector)
const Action = React.memo(ViewSelector)
import Loader from '../../Loader'
const useStyles = makeStyles(styles)

const RowLoader = ({ isLoading, children }) => {
  return !!isLoading ? <TableCell colSpan={15}><Loader isLoading={isLoading} /></TableCell> : children
}
const FormRow = (props) => {
  const {
    row,
    rowId,
    groupIndex,
    setSelected,
    selectedIds,
    onSelect,
    enableRowSelection,
    enableRowActions,
    enableRowColor,
    onRowClick,
    enableRowClick,
    isLoading,
    rowIndex,
    children
  } = props
  const [isNew, setIsNew] = useState(false)
  const classes = useStyles(_.merge({}, props, { isNew, enableRowColor, rowIndex }))
  const isSelected = _.includes(selectedIds, rowId)
  const rowChildren = TypeUtils.allByType(children, Row)
  const actionChildren = TypeUtils.allByType(children, Action)
  useEffect(() => {
    if (!!isNew) {
      const timer_id = setTimeout(() => setIsNew(false), 3000)
      return () => clearTimeout(timer_id)
    }
    return _.noop
  }, [isNew])
  useEffect(() => {
    return () => !!isLoading && setIsNew(true)
  }, [isLoading])
  return (
    <TableRow
      hover={!!enableRowClick}
      selected={isSelected}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      onClick={() => !!enableRowClick && !!onRowClick && onRowClick(rowId, row)}
      className={classes.row}
    >
      <RowLoader isLoading={isLoading}>
        {!!enableRowSelection && (
          <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              color="primary"
              indeterminate={false}
              checked={isSelected}
              onChange={() => onSelect(rowId)}
            />
          </TableCell>
        )}
        <ViewSelector isSelected={isSelected} row={row} rowId={rowId} groupIndex={groupIndex}>{rowChildren}</ViewSelector>
        {
          enableRowActions && (
            <TableCell padding="normal" align={'center'} size={'small'} onClick={(e) => e.stopPropagation()}>
              <ViewSelector
                controls={{
                  toggleSelection: () => onSelect(rowId),
                  setSelected
                }}
                table={{ selectedIds }}
                row={row} rowId={rowId} groupIndex={groupIndex}
                action={{ record_ids: [rowId], source: 'row', type: 'local', isSelected }}
              >
                {actionChildren}
              </ViewSelector>
            </TableCell>
          )
        }
      </RowLoader>
    </TableRow>
  );
}


const Body = (props) => {
  const {
    rows,
    rowKey,
    enableGrouping,
    enableRowLoading,
    iterators,
    groupSort,
    ...rest
  } = props

  const row_iterator = !!iterators
    ? _.compact(_.flatten([iterators, _.has(rows, '_create') ? '_create' : null]))
    : _.keys(rows)

  return (
    <TableBody>
      {_.map(row_iterator, (groupId, rowIndex) => {
        const rowGroup = _.get(rows, groupId)
        return _.map(_.flatten([rowGroup]), (row, groupIndex) =>
          <FormRow
            isLoading={groupId !== '_create' && !!enableRowLoading && HookUtils.getRecordLoading(row)}
            key={`${groupId}.${groupIndex}`}
            {...rest}
            row={row}
            rowId={groupId}
            groupIndex={groupIndex}
            rowIndex={rowIndex}
          />
        )
      })}
    </TableBody>
  );
}

export default React.memo(Body)

export {
  Row as TableRow,
  Action as TableRowActions,
}