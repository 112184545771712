import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

const withLicense = Component => ({ record_id, onClick: onInputClick, ...rest }) => {
  const [license] = hooks.useModel('license', [record_id], {
    populate: ['group'],
    single: true
  })

  const onClick = useCallback(e => {
    !!onInputClick && onInputClick(e)
    !onInputClick && window.open(`/adherent/${license?.group}/license/${record_id}`, '_blank')
    e.stopPropagation()
  }, [onInputClick, record_id, license])

  const label = _.join(_.compact([
    license?.license_client_id,
    '-',
    _.join([I18n.t('group.label', { count: 1 }), ':'], ''),
    license?.group?.group_client_id,
    license?.group?.name
  ]), ' ')

  const mergedProps = {
    label,
    onClick,
    ...rest
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withLicense
