import _ from 'lodash'

function handleVariant(variant) {
  return { variant }
}
function handleOptions(options) {
  return {
    ...handleVariant(_.get(options, 'variant'))
  }
}
class Callbacks {
  static openNotificationHandler(snackbar) {
    return async function openNotification(text, options, native_options) {
      try {
        return await snackbar.enqueueSnackbar(text, {
          ...handleOptions(options),
          ...native_options,
        })
      }
      catch (err) {
        console.warn("Failed to display snackbar message", err)
      }
    }
  }
  static closeNotificationHandler(snackbar) {
    return function closeNotification(snackbar_id, options, native_options) {
      snackbar?.closeSnackbar(snackbar_id)
    }
  }
}

export default Callbacks