const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  rootChip: {
    margin: '4px'
  }
})

export default styles
