import React, { useMemo } from 'react'
import _ from 'lodash'
import { InputAdornment, TextField } from '@mui/material'
import { SingleOptionSelectorField } from '../OptionSelector'

const TextComponent = (props) => {
  const {
    allowNone,
    noneValue,
    name,
    label,
    value,
    onChange,
    disabled,
    emptyValue = '-',
    meta,
    InputProps,
    isReady,
    children,
    _adornment,
    type,
    enableCheckbox = false,
    _checkboxProps,
    ...custom
  } = props

  const mergerInputProps = _.merge({}, { disableUnderline: !!disabled }, InputProps)

  const valueFormatted = useMemo(() => {
    if (!_.isNil(value))
      return value
    else if (!!disabled && !_.isNil(emptyValue))
      return emptyValue
    else return ''
  }, [value, disabled, emptyValue])

  const customLabel = !!enableCheckbox ? <SingleOptionSelectorField
    name={`${name}--checkbox`}
    optionLabel={label}
    _formGroup={{ sx: { pl: 1, fontSize: 21 } }}
    alwaysInclude
    {..._checkboxProps}
  /> : label
  return (
    <TextField
      onChange={e => !!onChange && onChange(_.get(e, 'target.value'))}
      value={valueFormatted}
      label={customLabel}
      disabled={disabled}
      type={type}
      fullWidth
      InputProps={{
        ...mergerInputProps,
        startAdornment: <InputAdornment key={`${name || label}_start`} position='start' {..._adornment}>{InputProps?.startAdornment}</InputAdornment>,
        endAdornment: <InputAdornment key={`${name || label}_end`} position='end' {..._adornment}>{InputProps?.endAdornment}</InputAdornment>
      }}
      {...custom}
    />
  )
}

export default React.memo(TextComponent)
