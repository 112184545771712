import React, { useCallback } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { makeStyles } from '@mui/styles'
import styles from './styles'
import { withSelector } from '@front/volcanion'

import TypeUtils from '@front/volcanion/utils/type'

import ViewSelector from '@front/volcanion/components/ViewSelector'

const useStyles = makeStyles(styles)

const TabHeader = React.memo(ViewSelector)


const TabsComponent = (props, context) => {
  const {
    displayEmpty,
    name,
    value,
    onChange,
    variant = 'standard',
    options = [],
    meta,
    selectorProps,
    children,
    isLoading,
    onInputChange,
    ...custom
  } = props

  const {
    getOptionLabel,
    getOptionValue,
    getOptionKey,
    getOptionProps,
  } = selectorProps

  const handleChange = useCallback((event, newValue) => {
    !!onChange && onChange(newValue)
    event.stopPropagation()
  }, [onChange])

  const headerChildren = TypeUtils.allByType(children, TabHeader)

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange} {...custom}>
        {_.map(options, tab =>
          <Tab
            {...getOptionProps(tab)}
            key={getOptionKey(tab)}
            label={<ViewSelector option={tab} selectorProps={selectorProps}>{headerChildren}</ViewSelector>}
            value={getOptionValue(tab)}
            disabled={tab.disabled}
            sx={{ maxWidth: 'unset' }}
          />
        )}
      </Tabs>
    </Box>
  )
}

export default React.memo(withSelector(TabsComponent))
export {
  TabHeader
}