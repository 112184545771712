import React, { useCallback, useEffect, useMemo } from 'react'
import { Form, Section, hooks } from '@front/volcanion'
import Table, { TableRow, TableActions, TableRowActions } from '../Table'
import { EditModeAction, AddRowAction, ModifyRowAction, ModifySelectedAction, CancelSelectedAction, SaveRowAction, SaveSelectedAction } from './Actions'

import TypeUtils from '@front/volcanion/utils/type'
import HookUtils from '@front/volcanion/utils/hooks'
import Callbacks from './callbacks'

const useSearchContext = (model_name, model_func, config) => {
  switch (config?.listener_type) {
    case 'attached':
      const search_result = hooks.useSearchListenerList(model_name, config.listener_id || config.search_id)
      const search_status = hooks.useSearchListenerStatus(model_name, config.listener_id || config.search_id)
      const pagination = hooks.useSearchListenerPagination(model_name, config.listener_id || config.search_id)
      const [populated_result] = hooks.useModel(model_name, search_result, _.omit(config, ['search_id', 'listener_id']))
      return [populated_result, _.noop, _.merge({}, search_status, pagination)]
    default:
      return hooks.useModelSearch(model_name, model_func, _.merge({}, config, { enableCount: true }))
  }
}

const FormTable = (props) => {
  const {
    model_name,
    name = `${model_name}_form_table`,
    model_func = 'get',
    controller,
    loadOnMount = true,
    config,
    tableProps: inputTableProps = {},
    formProps: inputFormProps = {},
    enableRowClick: inputEnableRowClick = false,
    children
  } = props
  const {
    recordToForm = _.identity,
    formToRecord = _.identity,
    getEmptyForm,
    initialState,
    ...formProps
  } = inputFormProps

  const {
    allowEditMode = false,
    allowRowModify = !!allowEditMode,
    allowRowGenerate = !!allowEditMode,
    groupBy: inputGroupBy,
    ...tableProps
  } = inputTableProps


  const [isReadOnly] = hooks.useGlobalFormState(name, 'isReadOnly')
  const enableRowClick = inputEnableRowClick || !!isReadOnly
  const internal_controller = useSearchContext(model_name, model_func, config)
  const [generateRecord] = hooks.useModelFunction(model_name, 'generate')
  const [modifyRecord] = hooks.useModelFunction(model_name, 'modify')
  const [records, search, search_status, search_controls] = !!controller ? controller : internal_controller
  const { primary_key } = hooks.useModelSchema(model_name)
  const isMounted = hooks.useGlobalFormStatus(name, 'isMounted')
  const reload = hooks.useGlobalFormFunction(name, 'reload')
  const removeEnabledSections = hooks.useGlobalFormFunction(name, 'removeEnabledSections')
  const recordKeys = _.map(records, primary_key)
  const ready_state = HookUtils.getReadyState(search_status)

  const groupBy = !!inputGroupBy ? inputGroupBy : primary_key
  const grouped_records = _.reduce(_.groupBy(records, groupBy), (acc, grouped_record, grouped_key) => _.set(acc, grouped_key, _.map(grouped_record, recordToForm)), {})

  const onSubmit = useCallback(Callbacks.onSubmitHandler(formToRecord, generateRecord, modifyRecord), [formToRecord, generateRecord, modifyRecord])
  const onSubmitSuccess = useCallback(Callbacks.onSubmitSuccessHandler(search, removeEnabledSections), [search, removeEnabledSections])
  const onSubmitFailed = useCallback(Callbacks.onSubmitFailedHandler(), [])

  useEffect(() => {
    !!ready_state && !!isMounted && reload(grouped_records)
  }, [recordKeys.join(',')])

  const rowChildren = TypeUtils.allByType(children, TableRow)
  const tableActionChildren = TypeUtils.allByType(children, TableActions)
  const tableRowActionChildren = TypeUtils.allByType(children, TableRowActions)
  const otherChildren = TypeUtils.withoutTypes(children, TableRow, TableActions, TableRowActions)

  const parentFormName = hooks.useFormName()
  const [parentIsReadOnly] = hooks.useFormState('isReadOnly')

  const initialIsReadOnly = !parentFormName || (!!parentIsReadOnly && !formProps?.disableParentDisabledListener)
  return (
    <Form
      disableParentSubmitListener={false}
      disableParentDisabledListener={false}
      name={name}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailed={onSubmitFailed}
      {...formProps}
      initialValues={grouped_records}
      initialState={_.merge({}, { isReadOnly: initialIsReadOnly, primary_key, model_name }, initialState)}
      status={search_status}
      forwardValues
      forwardKey={'rows'}
      onSubmit={onSubmit}
      closeOnSuccess={false}
    >
      <Table
        paginationState={search_status}
        paginationControls={search_controls}
        disableIterators={!!inputGroupBy}
        enableRowClick={enableRowClick}
        {...tableProps}
      >
        {otherChildren}
        <TableActions>
          {!!allowEditMode && <EditModeAction />}
          {!!allowRowGenerate && <AddRowAction />}
          {(!!allowRowModify || !!allowRowGenerate) && <SaveSelectedAction />}
          {!!allowRowModify && <ModifySelectedAction />}
          {!!allowRowModify && <CancelSelectedAction />}
          {tableActionChildren}
        </TableActions>
        <TableRow>
          <Section getName={({ rowId }) => rowId}>
            <Section getName={({ groupIndex }) => groupIndex}>
              {rowChildren}
            </Section>
          </Section>
        </TableRow>
        <TableRowActions>
          <Section getName={({ rowId }) => rowId}>
            <Section getName={({ groupIndex }) => groupIndex}>
              {(!!allowRowModify || !!allowRowGenerate) && <SaveRowAction />}
              {!!allowRowModify && <ModifySelectedAction />}
              {!!allowRowModify && <CancelSelectedAction />}
              {tableRowActionChildren}
            </Section>
          </Section>
        </TableRowActions>
      </Table>
    </Form>
  )

}

export default FormTable
export * from './Actions'
