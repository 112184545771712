import LeafletListener from '../listener'
import BaseLayer from './class'

class BaseListener extends LeafletListener {
  generateLeafletInstance() {
    return new BaseLayer(this, this.getOption('url'), this.getOptions())
  }
  hasValidCoordinates() {
    return true
  }
}
export default BaseListener