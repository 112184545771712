import React, { useState } from 'react'
import { I18n } from '@front/volcanion'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'

import TextComponent from '../../Text'
import { makeStyles } from '@mui/styles'
import moment from "moment"
import styles from './styles'

const useStyles = makeStyles(styles)


const CompactDateComponent = props => {
  const {
    value = null,
    onChange,
    onBlur,
    onFocus,
    onError = _.noop,
    helperText,
    error,
    isLoading,
    meta,
    inputFormat = "DD/MM/YYYY HH:mm",
    outputFormat = "YYYY-MM-DD HH:mm",
    mask = "__/__/____ __:__",
    autoAdjustPeriod,
    autoAdjustUnit = 'month',
    inputRef,
    OpenPickerButtonProps = {},
    renderInputProps,
    ...custom
  } = props

  const momentValue = !value ? value : moment.utc(value).local().format()
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} >
      <DesktopDateTimePicker
        onAccept={(e) => onChange(moment(e).utc().format())}
        onChange={(e, keyboardValue) => (!!keyboardValue || !e?.isValid()) && onChange(e?.isValid() ? moment(e).utc().format() : undefined)}
        onError={err => !!err ? onError(I18n.t(`sqr.datetime.${err}`)) : onError(null)}
        value={momentValue}
        inputFormat={inputFormat}
        error={error}
        helperText={helperText}
        mask={mask}
        renderInput={params => (
          <TextComponent
            {..._.merge({}, params, renderInputProps)}
            meta={meta}
            helperText={helperText}
            error={error}
            emptyValue={''}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )
        }
        OpenPickerButtonProps={{ tabIndex: -1, ...OpenPickerButtonProps }}
        {...meta}
        {...custom}
      />
    </LocalizationProvider>
  )
}

export default React.memo(CompactDateComponent)
