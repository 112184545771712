import React from 'react'
import { TableAction } from '../..'
import { hooks } from '@front/volcanion'


const ModifyRow = (props) => {
  const record_ids = _.get(props, 'action.record_ids')
  const addEnabledSections = hooks.useFormFunction('addEnabledSections')
  const removeEnabledSections = hooks.useFormFunction('removeEnabledSections')
  const { isReadOnly } = hooks.useLocalSectionState()
  const isFormReadOnly = hooks.useFormStatus('isReadOnly')
  if (!!isFormReadOnly)
    return null
  return (
    <TableAction
      label={"Modify"}
      icon={'edit'}
      {...props}
      onClick={() => !!isReadOnly ? addEnabledSections(record_ids) : removeEnabledSections(record_ids)}
    />
  )
}

export default React.memo(ModifyRow)