import React from 'react'

const GIF = ({ path, source, style, ...rest }) => {
  return (
    <img src={source?.uri || path} style={{ width: '100%', height: '100%', ...style }} {...rest} />
  )
}

export default GIF
export { GIF }
