import LeafletListener from '../listener'
import Polygon from './class'

class PolygonListener extends LeafletListener {
  generateLeafletInstance() {
    return new Polygon(this, this.getActiveCoordinates(), this.getOptions())
  }
  onPositionChange(prev_positions, new_positions) {
    this.getInstance().setLatLngs(new_positions)
    return super.onPositionChange(prev_positions, new_positions)
  }
}

export default PolygonListener