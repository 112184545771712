const styles = theme => {
  return ({
    content: ({ option, selectorProps, getContentStyle, ...props }) => {
      const { isOptionSelected } = selectorProps
      if (!!getContentStyle) return getContentStyle(option, theme, selectorProps)
      else if (!!isOptionSelected(option)) {
        return {

          color: theme.palette.extraWhite,
          backgroundColor: theme.palette.primary.main
        }
      }
      else return {

      }
    }
  })
}
export default styles
