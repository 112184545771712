import React from 'react';
import { CDNGIF } from '@front/volcanion'
import { CircularProgress, LinearProgress, Box } from '@mui/material'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles';
import styles from './styles'
const useStyles = makeStyles(styles)

const LoaderComponent = ({
  children,
  loaderKey = 'isLoading',
  className,
  size,
  style,
  nativeLoader = false,
  type = 'circle',
  _container,
  _loader,
  ...props
}) => {
  const classes = useStyles(props)
  if (_.get(props, loaderKey, false))
    if (!!nativeLoader && type === 'linear') {
      return <Box {..._container}><LinearProgress {..._loader} /></Box>
    }
    else {
      return (
        <div className={clsx(classes.root, className)} style={style} {..._container}>
          {!!nativeLoader && type === 'circle' && <CircularProgress size={size || 40} />}
          {!nativeLoader && <CDNGIF
            name={'loader'}
            variant={'dark'}
            {...(_.merge({}, _loader, { style: { width: size, height: size } }))}
          />
          }
        </div>
      )
    }

  return children
}
export default React.memo(LoaderComponent)
