class MapUtils {
  static isPositionValid(position) {
    return (
      (_.isArray(position) && _.compact(position).length === 2) ||
      (_.isObject(position) && !!position?.lat && !!position.lng)
    ) && _.every(position, _.isFinite)
  }
}

export default MapUtils
