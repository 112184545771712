const styles = (theme) => {
  return {
    container: {
      height: '50px',
      width: '300px'
    },
    characterSelected: {
      border: '2px solid !important',
      borderColor: `${theme.palette.primary.main} !important`
    },
    character: {
      lineHeight: '50px',
      fontSize: '36px',
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      border: '1px solid',
      borderColor: theme.palette.text.label,
      borderRadius: '8px',
      marginLeft: '8px'
    },
    characterInactive: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  }
}

export default styles