const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    margin: 0,
    width: '100%'
  },
  inputRoot: {
    marginTop: '0 !important'
  },
  // labelRoot: {
  //   color: `${theme.palette.text.label} !important`,
  //   opacity: `${theme.styling.opacity.label} !important`
  // },
  // inputRoot: {
  //   color: `${theme.palette.text.input} !important`,
  //   opacity: `${theme.styling.opacity.input} !important`,
  // },
  // buttonLabel: {
  //   color: `${theme.palette.text.label} !important`,
  //   opacity: `${theme.styling.opacity.label} !important`,
  // },
  input: {}
})

export default styles
