import React, { useCallback } from 'react';
import _ from 'lodash'
import { DialogActions, Button } from '@mui/material';
import { hooks } from '@front/volcanion';

const withConfirmation = (Component) => ({
  name,
  onClose,
  yesLabel = 'Oui',
  noLabel = 'Non',
  allowConfirm = true,
  allowCancel = true,
  onConfirm: onInputConfirm,
  onCancel: onInputCancel = _.noop,
  disableOnClose = false,
  children,
  ...custom
}) => {
  const [{ extra, state }] = hooks.useDialog(name)
  const title = _.get(extra, 'title')
  const description = _.get(extra, 'description')
  const onConfirm = useCallback(async () => {
    await onInputConfirm(state, { onClose, ...extra })
    !disableOnClose && onClose()
  }, [onInputConfirm, onClose, state, extra, disableOnClose])

  const onCancel = useCallback(async () => {
    await onInputCancel(state, extra)
    onClose()
  }, [onInputCancel, state, extra])

  return (
    <Component name={name} title={title} description={description} extra={extra} state={state} onClose={onClose} {...custom}>
      {children}
      <DialogActions>
        {!!allowCancel && <Button onClick={onCancel}>{noLabel}</Button>}
        {!!allowConfirm && <Button onClick={onConfirm}>{yesLabel}</Button>}
      </DialogActions>
    </Component>
  );
}
export default withConfirmation