import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import styles from './styles'
import { makeStyles } from '@mui/styles'

import TypeUtils from '@front/volcanion/utils/type'
import ViewSelector from '@front/volcanion/components/ViewSelector'

const useStyles = makeStyles(styles)

const ExpandPanelTitle = React.memo(ViewSelector)
const ExpandPanelContent = React.memo(ViewSelector)

const ExpandPanelComponent = (props) => {
  const {
    label,
    labelKey,
    disabled,
    open = false,
    toggleOpen = () => { },
    children,
    labelProps,
    accordionSummaryProps,
    iconProps,
    ...rest
  } = props
  const classes = useStyles(props)

  const expandPanelTitleChildren = TypeUtils.allByType(children, ExpandPanelTitle)
  const expandPanelContentChildren = TypeUtils.allByType(children, ExpandPanelContent)

  return (
    <Accordion key={label} classes={{ root: classes.rootCard }} expanded={open} disabled={disabled} elevation={0} {...rest} >
      <AccordionSummary
        onClick={() => toggleOpen(!open)}
        expandIcon={<ExpandMoreIcon color='primary' {...iconProps} sx={{ fontSize: '2vw', ...iconProps?.sx }} />}
        {...accordionSummaryProps}
      >
        <Grid container direction='row' justifyContent='center' alignItems="flex-end">
          <Grid item xs>
            <Box display='flex'>
              <Box hidden={!labelKey && !label}>
                <Typography fontSize={16} color='primary'> {labelKey ? I18n.t(labelKey) : label} </Typography>
              </Box>
              {_.map(expandPanelTitleChildren, (expandPanelTitleChild, key) => <ViewSelector {...TypeUtils.getProps(expandPanelTitleChild)} {...labelProps} key={key}>{expandPanelTitleChild}</ViewSelector>)}
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.rootExpansion }}>
        {!!open && _.map(expandPanelContentChildren, (expandPanelContentChild, key) => <ViewSelector {...TypeUtils.getProps(expandPanelContentChild)} {...props} key={key}>{expandPanelContentChild}</ViewSelector>)}
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(ExpandPanelComponent)

export {
  ExpandPanelTitle,
  ExpandPanelContent
}
