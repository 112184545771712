import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import _ from 'lodash'

import { TablePagination } from '@mui/material'

const Pagination = (props) => {
  const {
    onRowsChange,
    paginationState = {},
    paginationControls = {},
    ...rest
  } = props
  const { currentPage, pageSize, count: countPage } = paginationState
  const { setPageSize, changePage } = paginationControls

  const onRowsPerPageChange = useCallback(e => {
    setPageSize(parseInt(e.target.value, 10))
    !!onRowsChange && onRowsChange()
  }, [setPageSize, onRowsChange])

  const onPageChange = useCallback((e, new_page) => {
    changePage(new_page)
    !!onRowsChange && onRowsChange()
  }, [changePage, onRowsChange])

  return (
    <TablePagination
      component='div'
      rowsPerPageOptions={_.uniq(_.compact(_.orderBy([5, 10, 25, 100, 500, pageSize])))}
      count={countPage || 0}
      rowsPerPage={pageSize || 10}
      page={currentPage || 0}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      showFirstButton
      showLastButton
      labelRowsPerPage={I18n.t('sqr.page.rowPerPage')}
      sx={{
        minHeight: 0,
        color: 'primary.main',
        borderRadius: 1,
        border: 1,
        borderColor: 'primary.main',
        ".MuiToolbar-root": {
          minHeight: 0,
        },
        ".MuiTablePagination-actions": {
          '.Mui-disabled': { display: 'none' },
          '.MuiIconButton-root': {
            color: "primary.main",
            padding: 0
          },
        },
        ".MuiTablePagination-toolbar": {
          fontWeight: 600
        },
        ".MuiTablePagination-selectLabel": {
          margin: 0,
          fontWeight: 600
        },
        ".MuiTablePagination-displayedRows": {
          margin: 0
        }
      }}
      labelDisplayedRows={({ from, to, count }) => I18n.t('sqr.page.displayed_rows', { from, to, count })}
      {...rest}
    />
  )
}

export default React.memo(Pagination)
