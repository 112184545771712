import L, { DomUtil, Util } from 'leaflet'
import _ from 'lodash'

import ListenerBridge from '../bridge'
class LayersControl extends ListenerBridge(L.Control) {
  constructor(listener, options) {
    super(listener, options)
    this._container = null
    this.layers = {}
    this.active_layers = {}
  }
  addOverlay(layer) {
    _.set(this.layers, Util.stamp(layer), layer)
    this.notifyCallbacks({ name: 'overlay_add', data: { source: this, layer } })
  }
  removeLayer(layer) {
    _.unset(this.layers, Util.stamp(layer))
    this.notifyCallbacks({ name: 'overlay_remove', data: { source: this, layer } })
  }
  getLayers() {
    return _.map(this.layers, (layer) => layer)
  }
  onAdd(map) {
    this._container = DomUtil.create('div')
    this.notifyCallbacks({ name: 'control_add', data: { source: this } })
    return this._container;
  }
  onRemove(map) {
    this.notifyCallbacks({ name: 'control_remove', data: { source: this } })
  }
  enableLayer(layer) {
    _.set(this.active_layers, Util.stamp(layer), layer)
    return this.notifyCallbacks({ name: 'layer_enabled', data: { source: this, layer } })
  }
  disableLayer(layer) {
    _.unset(this.active_layers, Util.stamp(layer))
    return this.notifyCallbacks({ name: 'layer_disabled', data: { source: this, layer } })
  }
  getEnabledLayers() {
    return _.map(this.active_layers, (layer) => layer)
  }
  getEnabledLayerIds() {
    return _.map(this.active_layers, (layer) => layer.getLayerId())
  }
  getLayerMarkerCount(layer) {
    const child_layers = layer.getLayers()
    const [orphan_layers, group_layers] = _.partition(child_layers, (child_layer) => !child_layer.getParentListener().getOption('group_id'))
    return (_.keys(_.groupBy(group_layers, (group_layer) => group_layer.getParentListener().getOption('group_id'))).length || 0) + (orphan_layers.length || 0)
  }
  hasLayer(layer) {
    return _.has(this.layers, Util.stamp(layer))
  }
  isLayerEnabled(layer) {
    return _.has(this.active_layers, Util.stamp(layer))
  }
}


export default LayersControl

