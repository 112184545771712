import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Button } from '@mui/material'
import { ModelAutocompleteField, FormDialog, DialogContent } from '@front/squirtle'

const ExportButtonComponent = ({ handleExport, onSubmit, model_name, current_path }) => <Box>
  <FormDialog
    name={`export_${model_name}`}
    formProps={{ onSubmit: onSubmit }}
  >
    <DialogContent>
      <ModelAutocompleteField
        label={I18n.t('template.label', { count: -1 })}
        name={'template'}
        model_name='template'
        loadOnFocus
        searchMode='search'
        labelKeys={['name_translated']}
        allowNone={false}
        config={{
          populate: ['name_trkey'],
          initial_filter: { page: { path: current_path } },
          forced_filter: { page: { path: current_path } }
        }}
        required
      />
    </DialogContent>
  </FormDialog>
  <Button onClick={handleExport} variant='contained' size='small'>
    {I18n.t('action.export')}
  </Button>
</Box>

export default React.memo(ExportButtonComponent)
