import LeafletListener from '../listener'
import LayersControl from './class'

class LayersControlListener extends LeafletListener {
  generateLeafletInstance() {
    return new LayersControl(this, this.getOptions())
  }
  hasValidCoordinates() {
    return true
  }
  addToParent() {
    this.getInstance().addTo(this.getParentNode())
    this.getParentControl()?.notifyCallbacks({ name: 'parent_add', data: { source: this, parent: this.getParentNode() } })
    return this.notifyCallbacks({ name: 'parent_add', data: { source: this, parent: this.getParentNode() } })
  }
  removeFromParent() {
    this.getInstance()?.remove()
    this.getParentControl()?.notifyCallbacks({ name: 'parent_remove', data: { source: this, parent: this.getParentNode() } })
    return this.notifyCallbacks({ name: 'parent_remove', data: { source: this, parent: this.getParentNode() } })
  }
  getParentNode() {
    return this.getInfoKey('map')
  }
  getParentClusterLayer() {
    return null
  }
}
export default LayersControlListener