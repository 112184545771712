import React, { useEffect, useState, useMemo } from 'react'
import { Grid } from '@mui/material'

import Date from './Date'
import Time from './Time'
import Compact from './Compact'
import Memos from './memos'
import moment from 'moment'

function parseError(error, type, value) {
  if (error === 'required')
    return {
      error: _.isEmpty(value),
      helperText: _.isEmpty(value) ? error : undefined
    }
  return {
    error: _.isObject(error) ? !!_.get(error, type) : !!error,
    helperText: _.isObject(error) ? _.get(error, type) : error
  }
}
const DateTimeComponent = (props) => {
  const {
    value,
    onChange,
    dateProps = {},
    timeProps = {},
    enableTime = true,
    default_time = null,
    enableDate = true,
    disabled,
    disablePast,
    tolerance = 5,
    onError,
    error,
    helperText,
    meta,
    inputRef,
    compact = false,
    ...custom
  } = props

  const dateFormat = _.get(dateProps, 'outputFormat') || 'YYYY-MM-DD'
  const timeFormat = _.get(timeProps, 'outputFormat') || 'HH:mm'

  const sanitized_date = useMemo(() => Memos.getSanitized(value, false, { output: dateFormat }), [value, dateFormat])
  const sanitized_time = useMemo(() => Memos.getSanitized(value, false, { output: timeFormat }), [value, timeFormat])

  const [date, setDate] = useState(sanitized_date)
  const [date_error, setDateError] = useState(null)
  const [time, setTime] = useState(sanitized_time)
  const [time_error, setTimeError] = useState(null)

  const minTime = useMemo(() => !!date && moment(date, dateFormat).isSame(moment(), 'day') ? moment().subtract(tolerance, 'minutes') : undefined, [tolerance, date, timeFormat])
  const dateErrorProps = parseError(helperText, 'date', date)
  const timeErrorProps = parseError(helperText, 'time', time)

  useEffect(() => {
    if (!disabled) {
      if (!!date && !!time)
        onChange(moment(`${date} ${time}`, `${dateFormat} ${timeFormat}`).utc().format())
      else if (!!date && !time && !!default_time)
        onChange(moment(`${date} ${default_time}`, `${dateFormat} ${timeFormat}`).utc().format())
      else onChange(null)
    }
  }, [date, time])

  useEffect(() => {
    if (!disabled) {
      if (!date_error && !time_error)
        onError(null)
      else onError({ date: date_error, time: time_error })
    }
  }, [date_error, time_error])

  useEffect(() => {
    if (!disabled && !!value) {
      setDate(sanitized_date)
      setTime(sanitized_time)
    }
    if (!!disabled && !value && !!date)
      setDate(null)
    if (!!disabled && !value && !!time)
      setTime(null)
  }, [value])

  return (
    <Grid container>
      <Grid item xs={12}>
        {!!compact && <Compact
          onChange={onChange}
          onError={onError}
          error={error}
          value={value}
          disablePast={disablePast}
          disabled={disabled}
          meta={meta}
          inputRef={inputRef}
          helperText={helperText}
          {...custom}
          {...dateProps}
        />
        }
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {!compact && !!enableDate && <Grid item xs>
            <Date
              onParentChange={onChange}
              onChange={setDate}
              onError={setDateError}
              value={date}
              disablePast={disablePast}
              disabled={disabled}
              meta={meta}
              inputRef={inputRef}
              {...custom}
              {...dateProps}
              {...dateErrorProps}
            />
          </Grid>
          }
          {!compact && !!enableTime &&
            <Grid item xs>
              <Time
                onParentChange={onChange}
                onChange={setTime}
                onError={setTimeError}
                value={time}
                minTime={minTime}
                disabled={disabled}
                meta={meta}
                {...custom}
                {...timeProps}
                {...timeErrorProps}
              />
            </Grid>
          }
        </Grid>
      </Grid>

    </Grid>
  )
}

export default React.memo(DateTimeComponent)
