import React from 'react'

import { ReactSVG } from 'react-svg'
import { SvgIcon } from '@mui/material'

const InnerSVG = props => <ReactSVG {...props} />

const SVG = ({ sx, fill, source, ...rest }) =>
  <SvgIcon
    component={InnerSVG}
    {...rest}
    src={source}
    sx={{ color: fill, ...sx }}
  />

export default SVG
export { SVG }