import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withUser = Component => ({ record_id, onClick: onInputClick, ...rest }) => {
  const [user] = hooks.useModel('user', [record_id], {
    populate: ['info', 'customerinfo'],
    single: true
  })

  const onClick = useCallback(e => {
    !!onInputClick && onInputClick(e)
    !onInputClick && window.open(`/client/${record_id}`, '_blank')
    e.stopPropagation()
  }, [onInputClick, record_id])

  const label = _.join(_.compact([user?.customerinfo?.client_number, _.join(_.compact([user?.info?.last_name, user?.info?.first_name]), ' ')]), ' - ')

  const mergedProps = {
    label,
    onClick,
    ...rest
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withUser
