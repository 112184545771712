import React from 'react'
import DialogComponent from './component'
import withContainer from './container'

import withInformation from './variants/Information'
import withConfirmation from './variants/Confirmation'
import withForm from './variants/Form'
import withModelForm from './variants/ModelForm'

const Dialog = React.memo(withContainer(DialogComponent))
const InformationDialog = React.memo(withContainer(withInformation(DialogComponent)))
const ConfirmationDialog = React.memo(withContainer(withConfirmation(DialogComponent)))
const FormDialog = React.memo(withContainer(withForm(DialogComponent)))
const ModelFormDialog = React.memo(withContainer(withModelForm(DialogComponent)))

export default Dialog
export { Dialog, InformationDialog, ConfirmationDialog, FormDialog, ModelFormDialog }
export * from './component'

