import React, { useState } from 'react'
import ViewSelector from '@front/volcanion/components/ViewSelector'
import { ExpandPanelComponent, ExpandPanelTitle, ExpandPanelContent } from '../../components'
import { Box, IconButton, Typography } from '@mui/material'
import { SettingsOverscan } from '@mui/icons-material'

const Portal = ({ children, title, defaultOpen, hasMinimize, isMinimized, setMinimized, ...rest }) => {
  const [isOpen, setOpen] = useState(defaultOpen)

  return (
    <ExpandPanelComponent open={isOpen} toggleOpen={setOpen} iconProps={{ sx: { fontSize: 30 } }} accordionSummaryProps={{
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        minHeight: 0,
        height: '15px'
      }
    }}
    >
      <ExpandPanelTitle>
        {!!title && !isMinimized && (
          <Typography variant={'h5'} sx={{ fontSize: 'h6.fontSize', whiteSpace: 'initial' }}>
            {title}
          </Typography>
        )}
        {!!hasMinimize && (
          <Box display={'flex'} flex={1} justifyContent={'flex-end'}>
            <IconButton onClick={(e) => {
              setMinimized(!isMinimized)
              e.stopPropagation()
            }}
            >
              <SettingsOverscan sx={{ fontSize: 20 }} color='primary' />
            </IconButton>
          </Box>
        )}
      </ExpandPanelTitle>
      <ExpandPanelContent>
        <ViewSelector key={`layerControl`} {...rest} isMinimized={isMinimized} >
          {children}
        </ViewSelector>
      </ExpandPanelContent>
    </ExpandPanelComponent>
  )
}

export default Portal