import React from 'react'
import OptionSelectorComponent from './component'
import { withField, withModel, withAddress } from '@front/volcanion'
import withSingle from './withSingle'
const SingleOptionSelectorComponent = React.memo(withSingle(OptionSelectorComponent))
const ModelOptionSelectorComponent = React.memo(withModel(OptionSelectorComponent))
const AddressOptionSelectorComponent = React.memo(withAddress(OptionSelectorComponent))

const OptionSelectorField = React.memo(withField(OptionSelectorComponent, { disableNone: true }))
const SingleOptionSelectorField = React.memo(withSingle(OptionSelectorField))
const ModelOptionSelectorField = React.memo(withModel(OptionSelectorField))
const AddressOptionSelectorField = React.memo(withAddress(OptionSelectorField))

export default OptionSelectorComponent
export {
  OptionSelectorComponent, SingleOptionSelectorComponent, OptionSelectorField, SingleOptionSelectorField,
  ModelOptionSelectorComponent, AddressOptionSelectorComponent, ModelOptionSelectorField, AddressOptionSelectorField
}

