import React from 'react'
import L from 'leaflet'
import _ from 'lodash'
import { renderToString } from 'react-dom/server'
import './styles.scss'
import { PieChart } from 'react-minimal-pie-chart';
import ListenerBridge from '../bridge'

const ClusterIcon = ({ cluster }) => {
  const markers = cluster.getAllChildMarkers()
  const groups = _.groupBy(markers, (marker) => marker.getStyle('color'))
  const data = _.map(groups, (group, color) => ({ title: '', value: group.length, color }))
  const growSize = _.min([markers.length * 4 + 32, 96])
  return <div style={{ width: growSize, height: growSize, backgroundColor: 'transparent' }}>
    <PieChart
      data={data}
      lineWidth={13}
      paddingAngle={18}
      rounded
      label={({ dataEntry }) => dataEntry.value}
      labelStyle={(index) => ({
        fill: data[index].color,
        fontSize: '20px',
        fontFamily: 'sans-serif',
      })}
      labelPosition={60}
    />
  </div>
}


function iconCreateFunction(cluster) {
  return L.divIcon({ className: 'muted', html: renderToString(<ClusterIcon cluster={cluster} />) })
}
class Cluster extends ListenerBridge(L.MarkerClusterGroup) {
  constructor(listener, options) {
    super(listener, _.merge({
      iconCreateFunction,
      animate: true,
      animateAddingMarkers: true
    }, options))
  }
}

export default Cluster