import React from 'react'

import { TableRow, TableCell, Typography } from '@mui/material'

const DefaultEmptyRow = ({ label, ...rest }) => {
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Typography sx={{ color: 'grey' }}>{label}</Typography>
      </TableCell>
    </TableRow>
  )
}

export default React.memo(DefaultEmptyRow)
