import React from 'react'
import { TableAction } from '../..'
import { hooks } from '@front/volcanion'


const EditMode = (props) => {
  const [isReadOnly, setReadOnly] = hooks.useFormState('isReadOnly')
  return (
    <TableAction
      label={"Toggle Edit Mode"}
      icon={'toggle'}
      {...props}
      onClick={() => setReadOnly(!isReadOnly)}
    />
  )
}

export default React.memo(EditMode)