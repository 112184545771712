import Memos from './memos'
import moment from 'moment'
class Callbacks {
  static onChangeHandler(onChange, setValue, outputFormat, adjustMoment = _.identity) {
    return function handleChange(moment_instance) {
      const sanitized_moment = Memos.getSanitized(moment_instance, true, { input: outputFormat })
      const adjusted_moment = adjustMoment(sanitized_moment)
      onChange(Memos.getSanitized(adjusted_moment, false, { input: outputFormat, output: outputFormat }))
      return !!setValue && setValue(adjusted_moment)
    }
  }
  static onBlurHandler(onChange, onBlur, setValue, inputFormat, outputFormat, adjustMoment = _.identity, defaultDateTime) {
    return function handleChange(e) {
      if (_.includes(['datePickerIcon', 'timePickerIcon'], _.get(e, 'relatedTarget.id'))) return
      const sanitized_moment = Memos.getSanitized(e.target.value, true, { input: inputFormat })
      const default_moment = moment(defaultDateTime())
      const adjusted_moment = !(sanitized_moment?.isValid()) ? adjustMoment(default_moment) : adjustMoment(sanitized_moment)
      const final_moment = Memos.getSanitized(adjusted_moment, false, { output: outputFormat })
      onChange(final_moment)
      onBlur(final_moment)
      return !!setValue && setValue(final_moment)
    }
  }

}

export default Callbacks
