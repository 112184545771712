import React from 'react'
import MenuSelectorComponent from './component'
import { withField, withModel, withAddress } from '@front/volcanion'
const ModelMenuSelectorComponent = React.memo(withModel(MenuSelectorComponent))
const AddressMenuSelectorComponent = React.memo(withAddress(MenuSelectorComponent))
const MenuSelectorField = React.memo(withField(MenuSelectorComponent))
const ModelMenuSelectorField = React.memo(withModel(MenuSelectorField))
const AddressMenuSelectorField = React.memo(withAddress(MenuSelectorField))

export {
  MenuSelectorComponent,
  ModelMenuSelectorComponent,
  AddressMenuSelectorComponent,
  MenuSelectorField,
  ModelMenuSelectorField,
  AddressMenuSelectorField
}
export default MenuSelectorComponent
