import React, { useState, useRef, useEffect } from 'react'
import { Box, Grow, Popper } from '@mui/material'

import HookUtils from '@front/volcanion/utils/hooks'

import ButtonSelected from './Selected'
import ButtonList from './List'

const ButtonComponent = (props) => {
  const {
    options,
    variant = 'contained',
    size = 'medium',
    isLoading,
    matchKeys,
    disabled,
    ...rest
  } = props

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(rest.selectedIndex || 0)

  const buttonSelectedProps = {
    anchorRef,
    variant,
    size,
    isLoading,
    disabled,
    open,
    setOpen,
    options,
    selectedIndex,
  }

  const buttonListProps = {
    anchorRef,
    setSelectedIndex,
    setOpen,
    options
  }

  useEffect(() => {
    setSelectedIndex(0)
  }, [HookUtils.getRecordsWatcher(options)])

  return (<>
    {!_.isEmpty(options) &&
      <Box>
        <ButtonSelected {...buttonSelectedProps} />
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <ButtonList {...buttonListProps} />
            </Grow>
          )}
        </Popper>
      </Box >
    }
  </>
  )
}

export default React.memo(ButtonComponent)
