import { useCallback } from 'react'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '@front/volcanion/provider'
import useLeafletContext from './useLeafletContext'
const useAttachedListener = (listener_id, listener_type, callback) => {
  const context = useLeafletContext()
  const { mapId } = context
  return useSyncExternalStoreWithSelector(
    useCallback(ProviderInstance.getProvider(mapId)?.prepareAttachedSubscription(listener_id, listener_type), [mapId, listener_id, listener_type]),
    useCallback(() => callback(
      ProviderInstance.getProvider(mapId)?.getListener(listener_id)?.getInstance(),
      ProviderInstance.getProvider(mapId)?.getListener(listener_id),
      context
    ), [mapId, listener_id, context, callback]),
    null,
    _.identity,
    _.isEqual
  )
}

export default useAttachedListener