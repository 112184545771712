import React from 'react'
import { I18n } from '@front/volcanion/'

import _ from 'lodash'
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles';

import styles from './styles'
import ViewSelector from '@front/volcanion/components/ViewSelector';

const useStyles = makeStyles(styles)

const Header = (props) => {
  const {
    paginationControls,
    paginationState,
    selectedIds,
    existingIds,
    enableRowSelection,
    enableRowActionsHeader,
    enableRowActions,
    onSelectAll,
    children
  } = props

  const existing_selected = _.intersection(selectedIds, existingIds)
  //TODO add children action
  return (
    <TableHead>
      <TableRow>
        {!!enableRowSelection && (
          <TableCell variant='head' padding="checkbox" sortDirection={'desc'}>
            <Checkbox
              color="primary"
              indeterminate={(selectedIds || []).length > 0 && existing_selected.length !== existingIds.length}
              checked={existingIds.length > 0 && existing_selected.length === existingIds.length}
              onChange={onSelectAll}
            />
          </TableCell>
        )}
        <ViewSelector selectedIds={selectedIds} paginationControls={paginationControls} paginationState={paginationState}>{children}</ViewSelector>
        {!!enableRowActions &&
          <TableCell variant='head' padding="normal" align={'center'} size={'small'}>
            {!!enableRowActionsHeader && <Typography sx={{ color: 'text.label', textAlign: 'center', fontSize: 13, fontWeight: 600 }}> {I18n.t('action.label', { count: 2 })} </Typography>}
          </TableCell>
        }
      </TableRow>
    </TableHead >
  );
}

export default React.memo(Header)
