// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clusterGroup {
  float: left;
  width: 25px;
  height: 25px;
  shape-outside: circle();
  clip-path: circle();
  color: white;
  text-align: center;
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./squirtle/MapFactory/Cluster/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;AACF","sourcesContent":[".clusterGroup {\n  float: left;\n  width: 25px;\n  height: 25px;\n  shape-outside: circle();\n  clip-path: circle();\n  color: white;\n  text-align: center;\n  vertical-align: bottom;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
