const getComponents = (mode, primary) => ({
  MuiStepLabel: {
    defaultProps: {
      sx: {
        '.MuiSvgIcon-root': {
          color: 'grey'
        },
      },
    }
  },
  MuiSwitch: {
    defaultProps: {
      variant: 'standard',
      size: 'small',
    }
  },
  MuiInput: {
    defaultProps: {
      size: 'small'
    }
  },
  MuiTableCell: {
    defaultProps: {
      sx: {
        textAlign: 'center'
      }
    }
  },
  MuiInputLabel: {
    defaultProps: {
      shrink: true,
      size: 'small'
    }
  },
  MuiTextField: {
    defaultProps: {
      variant: "standard",
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: "standard",
      size: 'small',
    }
  },
  MuiFormLabel: {
    defaultProps: {
      variant: "standard",
      sx: 'small',
    }
  }
})

export default getComponents
