import LeafletListener from '../listener'
import Map from './class'

class MapListener extends LeafletListener {
  generateLeafletInstance() {
    return new Map(this, this.getParentProvider().getProviderId(), this.getOptions())
  }
  hasValidCoordinates() {
    return true
  }
  onOptionsUpdated(event) {
    const props = event?.data
    const prevProps = event?.prev_value
    _.map(prevProps?.eventHandlers, (callback, name) => this.getInstance().off(name, callback, this))
    _.map(props?.eventHandlers, (callback, name) => this.getInstance().on(name, callback, this))
    return super.onOptionsUpdated(event)
  }
  onInfoUpdated(event) {

  }
  getParentMap() {
    return this
  }
}


export default MapListener