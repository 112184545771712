import LeafletListener from '../listener'
import Popup from './class'

class PopupListener extends LeafletListener {
  generateLeafletInstance() {
    return new Popup(this, this.getActiveCoordinates(), this.getOptions())
  }
  isSinglePoint() {
    return true
  }
  onPositionChange(prev_position, new_position) {
    this.getInstance().setLatLng(new_position)
    return super.onPositionChange(prev_position, new_position)
  }
  onInstanceCreate(event) {
    this.getParentOverlay()?.on('popupopen', this._onPopupOpen, this)
    this.getParentOverlay()?.on('popupclose', this._onPopupClose, this)
    return this
  }
  onInstanceDestroy(event) {
    this.getParentOverlay()?.off('popupopen', this._onPopupOpen, this)
    this.getParentOverlay()?.off('popupclose', this._onPopupClose, this)
    return this
  }
  _onPopupOpen() {
    return this.notifyCallbacks({ name: 'popup_open', data: { source: this } })
  }
  _onPopupClose() {
    return this.notifyCallbacks({ name: 'popup_close', data: { source: this } })
  }
  addToParent() {
    this.getParentOverlay()?.bindPopup(this.getInstance())
    return this.getParentControl()?.notifyCallbacks({ name: 'parent_add', data: { source: this, parent: this.getParentOverlay() } })
  }
  removeFromParent() {
    this.getParentOverlay()?.unbindPopup()
    this.getParentMap()?.removeLayer(this.getInstance());
    this.getInstance()?.remove()
    return this.getParentControl()?.notifyCallbacks({ name: 'parent_remove', data: { source: this, parent: this.getParentOverlay() } })
  }
}

export default PopupListener