import React, { useMemo } from 'react'
import styles from './styles'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles(styles)
import RadioComponent from './Radio'
import SwitchComponent from './Switch'
const getValueDecorator = (type) => {
  switch (type) {
    case 'checkbox':
    case 'switch':
      return SwitchComponent
    case 'radio':
    case 'standard':
    default:
      return RadioComponent
  }
}
const OptionSelector = (props, context) => {
  const {
    multiple,
    valueType = !!multiple ? 'checkbox' : 'radio',
    isReady,
    ...custom
  } = props
  const Decorator = useMemo(() => getValueDecorator(valueType), [valueType])
  return <Decorator multiple={multiple} valueType={valueType} {...custom} />
}

export default React.memo(OptionSelector)
