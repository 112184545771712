class Callbacks {
  static onSubmitHandler(formToFilter, formToOptions, onSearch) {
    return function onSubmit(values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 4 ~ Callbacks ~ onSubmit ~ values, extra, meta, state", values, extra, meta, state)
      return onSearch(formToFilter(values, extra, meta, state), formToOptions(values, extra, meta, state))
    }
  }
  static onSubmitSuccessHandler(customOnSubmitSuccess) {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      customOnSubmitSuccess(result, values, extra, meta, state)
    }
  }
  static onSubmitFailedHandler(customOnSubmitFailed) {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("err", err)
      customOnSubmitFailed(err, values, extra, meta, state)
    }
  }
}

export default Callbacks
