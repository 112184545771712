import React from 'react'
import Action from '.'
import { hooks } from '@front/volcanion'
import _ from 'lodash'
const DialogAction = ({ name, getDialogExtra = _.noop, getDialogState = _.identity, ...props }) => {
  const [, { openDialog }] = hooks.useDialog(name)

  return (
    <Action
      {...props}
      onClick={(action_info) => openDialog(getDialogExtra(action_info), getDialogState(action_info))}
    />
  )
}

export default React.memo(DialogAction)