import { getTheme, getComponents, getTypography } from './'

const buildTheme = (customColor, mode) => {
  const palette = getTheme(mode, customColor)
  const typography = getTypography(mode, customColor)
  const components = getComponents(mode, customColor)

  return _.merge({}, { palette }, { components }, { typography })
}

export default buildTheme
