import L from 'leaflet'
import _ from 'lodash'
import ListenerBridge from '../bridge'

class Polyline extends ListenerBridge(L.Polyline) {
  refreshStyles(prev_styles, new_styles) {
    this.setStyle(_.merge({}, this.getDefaultStyles(), this.getStyles()))
    return super.refreshStyles(prev_styles, new_styles)
  }
}

export default Polyline