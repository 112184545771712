import React from 'react'
import { TableAction } from '../..'
import { hooks } from '@front/volcanion'


const ModifySelected = (props) => {
  const { action: { record_ids } } = props
  const isSectionEnabled = hooks.useFormFunction('isSectionEnabled')
  const effective_ids = _.filter(record_ids, (record_id) => !isSectionEnabled(record_id))
  const addEnabledSections = hooks.useFormFunction('addEnabledSections')
  const isFormReadOnly = hooks.useFormStatus('isReadOnly')
  if (!!isFormReadOnly || effective_ids.length === 0)
    return null
  return (
    <TableAction
      label={`Modify Selected (${record_ids.length})`}
      icon={'edit'}
      {...props}
      onClick={() => addEnabledSections(record_ids)}
    />
  )
}

export default React.memo(ModifySelected)