import React, { useCallback, useEffect, useState, useMemo } from 'react'
import moment from 'moment'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { I18n } from '@front/volcanion'

import TextComponent from '../../Text'
import Memos from '../memos'
import Callbacks from '../callbacks'

function adjustMomentHandler(autoAdjustPeriod, autoAdjustUnit, enableAutoAdjust) {
  return function adjustMoment(moment_instance) {
    if (!!moment_instance && moment_instance.isValid() && !!enableAutoAdjust) {
      if (moment_instance.isBefore(moment(), 'day')) {
        const adjusted_instance = moment(moment_instance).add(1, 'year')
        const limit_instance = moment().add(autoAdjustPeriod, autoAdjustUnit)
        if (adjusted_instance.isBefore(limit_instance, 'day'))
          return adjusted_instance
      }
    }
    return moment_instance
  }
}
const DateComponent = (props) => {
  const {
    value,
    onChange,
    onParentChange,
    onBlur,
    onFocus,
    helperText,
    error,
    isLoading,
    meta,
    inputFormat = "DD/MM",
    outputFormat = "YYYY-MM-DD",
    mask: inputMask,
    enableAutoAdjust = false,
    autoAdjustPeriod,
    autoAdjustUnit = 'month',
    inputRef,
    onKeyDown = _.noop,
    renderInputProps,
    onError = _.noop,
    required,
    defaultDateTime = _.noop,
    ...custom
  } = props

  const mask = useMemo(() => inputMask || _.map(inputFormat.split('/'), (segment) => _.fill(Array(segment.length), '_').join('')).join('/'), [inputMask, inputFormat])
  const sanitized_value = useMemo(() => Memos.getSanitized(value, true, { input: outputFormat }), [value, outputFormat])
  const [formatted_value, setFormatedValue] = useState(sanitized_value)
  const adjustDate = useCallback(adjustMomentHandler(autoAdjustPeriod, autoAdjustUnit, enableAutoAdjust), [autoAdjustPeriod, autoAdjustUnit, enableAutoAdjust])
  const handleChange = useCallback(Callbacks.onChangeHandler(onChange, setFormatedValue, outputFormat, adjustDate), [onChange, outputFormat, adjustDate])
  const handleBlur = useCallback(Callbacks.onBlurHandler(onChange, onBlur, setFormatedValue, inputFormat, outputFormat, adjustDate, defaultDateTime), [onChange, onBlur, inputFormat, outputFormat, adjustDate, defaultDateTime])

  useEffect(() => {
    if (moment.isMoment(sanitized_value))
      setFormatedValue(moment(sanitized_value, outputFormat).utc().isValid() ? moment(sanitized_value, outputFormat).utc().format() : moment(null, outputFormat).utc().format())
    if (sanitized_value == null && moment(formatted_value).isValid())
      setFormatedValue(null)
  }, [sanitized_value, outputFormat, formatted_value])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        onChange={(e, keyboardValue) => (!!keyboardValue || !e?.isValid()) && handleChange(e)}
        onAccept={handleChange}
        value={formatted_value}
        inputFormat={inputFormat}
        mask={mask}
        {...custom}
        inputRef={inputRef}
        error={error}
        onError={err => !!err ? onError(I18n.t(`sqr.date.${err}`)) : onError(null)}
        helperText={helperText}
        renderInput={(params) => (
          <TextComponent
            {..._.merge({}, params, renderInputProps)}
            meta={meta}
            helperText={helperText}
            error={error}
            emptyValue={''}
            onBlur={handleBlur}
            onFocus={onFocus}
            onKeyDown={e => onKeyDown(e, { onChange: handleChange })}
            required={required}
          />
        )}
        OpenPickerButtonProps={{ tabIndex: -1, id: 'datePickerIcon', sx: { color: 'icon.main' } }}
      />
    </LocalizationProvider>
  )
}

export default React.memo(DateComponent)
