import _ from 'lodash'
import React, { useCallback } from 'react'
import { Form, LocalProvider } from '@front/volcanion'
import Callbacks from './callbacks'

const withForm = Component => props => {
  const {
    name,
    onSearch,
    initialValues = {},
    initialStates = {},// @deprecated
    initialState = {},
    filterToState = _.identity,
    formToFilter = _.identity,
    filterToForm = _.identity,
    formToOptions = _.noop,
    submitOnMount,
    submitWatchers,
    submitOnReset = true,
    submitOnEnter = true,
    onSubmitSuccess: customOnSubmitSuccess = _.noop,
    onSubmitFailed: customOnSubmitFailed = _.noop,
    ...rest
  } = props

  const formProps = {
    name: name || 'search_form',
    initialState: _.merge({}, initialState, initialStates, filterToState(initialValues), { isReadOnly: false }),
    initialValues: filterToForm(initialValues),
    onSubmit: useCallback(Callbacks.onSubmitHandler(formToFilter, formToOptions, onSearch), [formToFilter, formToOptions, onSearch]),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(customOnSubmitSuccess), [customOnSubmitSuccess]),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(customOnSubmitFailed), [customOnSubmitFailed]),
    closeOnSuccess: false,
    submitOnEmpty: true,
    submitOnEnter,
    submitOnMount,
    submitWatchers,
    submitOnReset,
    disableParentSubmitListener: true,
    disableParentDisabledListener: true
  }
  return (
    <Form status={{ isReady: true }} {...formProps}>
      <LocalProvider context={{ formToFilter, ...formProps }}
      >
        <Component {...rest} />
      </LocalProvider>
    </Form>
  )
}

export default withForm
