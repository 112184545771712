import React from 'react'
import { Box, Typography } from '@mui/material'
import { Icon } from '@front/squirtle'

const StandardOption = (props) => {
  const {
    value,
    selectorProps,
    ...rest
  } = props
  const {
    getOptionLabel,
    getOptionKey,
    getOptionProps,
    getValueOption,
    getOptionIconProps,
    // DEPRECATED
    getOptionIcon
  } = selectorProps
  const matched_option = getValueOption(value)

  const { icon } = getOptionIconProps(matched_option) || {}

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!!icon && <Icon  {...getOptionIconProps(matched_option)} />}
      <Box sx={{ width: 1 }}>
        <Typography  {...getOptionProps(matched_option)} key={getOptionKey(matched_option)} {...rest}>
          {getOptionLabel(matched_option)}
        </Typography>
      </Box>
    </Box>
  )
}

export default React.memo(StandardOption)
