import React, { useMemo } from 'react'
import ChipOption from './chip'
import StandardOption from './standard'
const getValueDecorator = (type) => {
  switch (type) {
    case 'chip':
      return ChipOption
    case 'standard':
    default:
      return StandardOption
  }
}

const ValueDecoratorSelector = (props) => {
  const { valueType, value, ...rest } = props
  const Decorator = useMemo(() => getValueDecorator(valueType), [valueType])
  return _.map(_.flatten([value]), (v) => <Decorator key={v} value={v} {...rest} />)
}
export default React.memo(ValueDecoratorSelector)
