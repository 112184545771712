import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet.marker.slideto'

import { GestureHandling } from "leaflet-gesture-handling"

import "leaflet/dist/leaflet.css"
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css"

import ListenerBridge from '../bridge'

class Map extends ListenerBridge(L.Map) {

}

Map.addInitHook("addHandler", "gestureHandling", GestureHandling);


export default Map
