import moment from 'moment'
import _ from 'lodash'
export const isValidDate = (value) => {
  return moment(value, 'DD/MM').isValid() ? undefined : 'plalala'
}
export const isValidTime = (value) => {
  return moment(value, 'HH:mm').isValid() ? undefined : 'plalala'
}
export const isPast = (value) => moment.utc().isAfter(moment.utc(value)) ? "Time cannot be in the past" : undefined
export const isBeforeMinTime = (value, allValues, props) => {
  return 'test'
}
export const isRequired = (value, allValues, props) => {
  if (_.isEmpty(value) && !_.isInteger(value)) return 'validation.required'
  return undefined
}
export const isRequiredBlank = (value, allValues, props) => {
  if (_.isEmpty(value) && !_.isInteger(value)) return ' '
  return undefined
}
export const isPositive = (value, allValues, props) => {
  if (!_.isEmpty(value) && _.toInteger(value) < 0) return 'validation.format'
  return undefined
}
export const isRequiredAddress = (value, allValues, props) => {
  if (_.isEmpty(value)) return 'Champ obligatoire'
  return undefined
}

export const isPhoneNumber = (value) => {
  const hasError = !(_.startsWith(value, '+') || _.startsWith(value, '0')) || (value || '').length < 5
  return !!hasError ? 'order.client.validation.gsm.error' : undefined
}
