import colors from './colors'


const getTypography = (mode = 'light', primary) => ({
  h5: {
    color: primary[mode]
  },
  button: {
    color: mode === 'light' ? primary[mode] : 'rgba(255, 255, 255, .7)'
  },
  subtitle1: {
    color: mode === 'dark' ? 'rgba(255, 255, 255, .7)' : colors['grayDark']
  },
  fontFamily: [
    `"Montserrat", sans-serif`,
  ].join(','),
})

export default getTypography
