import _ from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '@front/volcanion/provider'
import { useProviderListener } from '@front/volcanion/hooks';

import shortid from 'shortid'
import { createPortal } from 'react-dom'
import { useAttachedListener, useLeafletContext } from '../hooks/Map'
import { LeafletProvider } from './Map/component'

const LeafletWrapper = (props) => {
  const {
    children,
    isOverlay,
    isPortal,
    listener_id: inputListenerId,
    ...rest
  } = props
  const { listener_type } = props
  const parent_context = useLeafletContext()
  const mapId = parent_context?.mapId
  const listener_id = useMemo(() => inputListenerId || shortid.generate(), [inputListenerId, listener_type])

  const [context, instance, { isReady }] = useSyncExternalStoreWithSelector(
    useProviderListener(mapId, listener_id, parent_context, rest, [mapId, rest], [], []),
    useCallback(() => [
      ProviderInstance.getProvider(mapId)?.getListener(listener_id)?.getLeafletContext(),
      ProviderInstance.getProvider(mapId)?.getListener(listener_id)?.getInstance(),
      ProviderInstance.getProvider(mapId)?.getFullListenerStatus(listener_id)
    ], [mapId, listener_id]),
    null,
    _.identity,
    _.isEqual
  )

  const container = useAttachedListener(listener_id, listener_type, useCallback((_instance) => _instance?._contentNode, []))

  useEffect(() => {
    !!isOverlay && instance?.update()
  }, [children, container, isOverlay])

  useEffect(() => () => ProviderInstance.getProvider(mapId)?.destroyListener(listener_id), [listener_id])


  if (!!isPortal && !!isReady)
    return !!container && createPortal(children, container)
  return !!isReady && (
    <LeafletProvider value={context}>
      {children}
    </LeafletProvider>
  )
}

export default LeafletWrapper