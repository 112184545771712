import Provider from '@front/volcanion/classes/providers'
import 'leaflet'
import 'leaflet.markercluster'
import 'leaflet.marker.slideto'
import _ from 'lodash'
import MapListener from './listener'
import ClusterListener from '../Cluster/listener'
import LayerListener from '../SuperLayer/listener'
import MarkerListener from '../Marker/listener'
import PolylineListener from '../Polyline/listener'
import PolygonListener from '../Polygon/listener'
import LayersControlListener from '../LayersControl/listener'
import PopupListener from '../Popup/listener'
import TooltipListener from '../Tooltip/listener'
import BaseListener from '../Base/listener'

class MapProvider extends Provider {
  getListenerClass(info, options) {
    switch (_.get(options, 'listener_type')) {
      case 'marker':
        return MarkerListener
      case 'base':
        return BaseListener
      case 'layer':
        return LayerListener
      case 'cluster':
        return ClusterListener
      case 'control':
        return LayersControlListener
      case 'polyline':
        return PolylineListener
      case 'polygon':
        return PolygonListener
      case 'popup':
        return PopupListener
      case 'tooltip':
        return TooltipListener
      case 'map':
      default:
        return MapListener
    }
  }
  async init() {
    return super.init(true)
  }
}

export default MapProvider