import React, { useMemo } from 'react'
import CheckboxMenu from './checkbox'
import StandardMenu from './standard'
const getMenuDecorator = (type) => {
  switch (type) {
    case 'checkbox':
      return CheckboxMenu
    case 'standard':
    default:
      return StandardMenu
  }
}

const MenuDecoratorSelector = (props) => {
  const { menuType, ...rest } = props
  const Decorator = useMemo(() => getMenuDecorator(menuType), [menuType])
  return (<Decorator  {...rest} />)
}
export default React.memo(MenuDecoratorSelector)
