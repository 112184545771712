import React from 'react'
import { TableAction } from '../..'
import { hooks } from '@front/volcanion'


const CancelSelected = (props) => {
  const { action: { record_ids } } = props
  const isSectionEnabled = hooks.useFormFunction('isSectionEnabled')
  const effective_ids = _.filter(record_ids, (record_id) => isSectionEnabled(record_id))
  const removeEnabledSections = hooks.useFormFunction('removeEnabledSections')
  const isFormReadOnly = hooks.useFormStatus('isReadOnly')
  if (!!isFormReadOnly || effective_ids.length === 0)
    return null
  return (
    <TableAction
      label={`Cancel Selected (${record_ids.length})`}
      icon={'edit'}
      {...props}
      onClick={() => removeEnabledSections(record_ids)}
    />
  )
}

export default React.memo(CancelSelected)