import _ from "lodash"

class Callbacks {
  static onSubmitHandler(formToRecord, generateRecord, modifyRecord) {
    return async function onSubmit(values, extra, meta, state) {
      const picked_values = _.has(extra, 'record_ids')
        ? _.pick(values, _.get(extra, 'record_ids'))
        : values
      return Promise.all(_.map(picked_values, (value, record_id) => {
        if (record_id === '_create')
          return Promise.all(_.map(value, (v) => generateRecord({ data: formToRecord(v, extra, meta, state) })))
        else return modifyRecord({ filter: { [_.get(state, 'primary_key')]: record_id }, updates: formToRecord(_.head(value), extra, meta, state) })
      }))
    }
  }
  static onSubmitSuccessHandler(search, removeEnabledSections) {
    return function onSubmitSuccess(result, final_values, extra, meta, state) {
      search()?.then(() => removeEnabledSections(_.keys(final_values)))
    }
  }
  static onSubmitFailedHandler(search) {
    return function onSubmitFailed(err, final_values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 13 ~ Callbacks ~ onFormSuccess ~ err, final_values, extra, meta, state", err, final_values, extra)

    }
  }

}

export default Callbacks