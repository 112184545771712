const styles = theme => ({
  row: ({ isNew, rowIndex, enableRowColor }) => {
    const rowColor = enableRowColor && (rowIndex % 2 ? '#e0e0e0' : '#ffffff')
    return {
      backgroundColor: !!isNew ? '#90EE9025' : rowColor,
      transition: !!isNew ? "background-color 0.5s" : ""
    }
  }
})

export default styles
