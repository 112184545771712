import React, { useState, useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion';
import _ from 'lodash'
import {
  Table,
  TableContainer,
  Toolbar,
  Box
} from '@mui/material'
import TypeUtils from '@front/volcanion/utils/type';
import ViewSelector from '@front/volcanion/components/ViewSelector';
import Callbacks from './callbacks'
import Loader from '../Loader'
import Header from './Header'
import Pagination from './Pagination';
import Body, { TableRow, TableRowActions } from './Body'
import HookUtils from '@front/volcanion/utils/hooks';

import TableDialogAction from './Action/Dialog'
import TableAction from './Action'
import DefaultEmptyRow from './DefaultEmptyRow';
const TableHeader = React.memo(ViewSelector)
const TableToolbar = React.memo(ViewSelector)
const TableActions = React.memo(ViewSelector)
const TableEmpty = React.memo(ViewSelector)

const Actions = ({ source, onSelectAll, setSelected, selectedIds, rows, children }) =>
  <ViewSelector
    controls={{
      toggleSelection: () => onSelectAll(),
      setSelected,
    }}
    table={{
      selectedIds,
      rows: _.flatten(_.values(rows))
    }}
    action={{ record_ids: selectedIds, source, type: 'global', isSelected: false }}
  >
    {children}
  </ViewSelector>

const TableComponent = (props) => {
  const {
    rows = [],
    rowKey,
    enableRowActionsHeader = false,
    enableRowActions = false,
    enableGlobalActions = false,
    enableHeaderEmptyRecord = true,
    enableRowColor = true,
    emptyRowLabel = I18n.t('sqr.table.empty'),
    enableGlobalActionsPlacement = 'top',
    enableRowClick,
    enableRowSelection = enableGlobalActions,
    enableToolbar = enableRowSelection || enableGlobalActions,
    enablePagination = true,
    enableRowLoading = true,
    paginationState,
    paginationControls,
    placementPagination = 'top',
    isLoading,
    children,
    onRowClick,
    onRowsChange,
    disableIterators = false,
    selectRowStateID
  } = props

  const grouped_rows = !!rowKey ? _.groupBy(rows, rowKey) : rows
  const isEmptyRecords = _.isEmpty(rows)
  const existingIds = _.keys(grouped_rows)
  const [localSelectedIds, setLocalSelected] = useState([])
  const [globalSelectedIds, setGlobalSelected] = hooks.useGlobalState(selectRowStateID)
  const [selectedIds, setSelected] = !!selectRowStateID ? [globalSelectedIds, setGlobalSelected] : [localSelectedIds, setLocalSelected]
  const headerChildren = TypeUtils.allByType(children, TableHeader)
  const rowChildren = TypeUtils.allByType(children, TableRow)
  const toolbarChildren = TypeUtils.allByType(children, TableToolbar)
  const tableActionChildren = TypeUtils.allByType(children, TableActions)
  const tableRowActionChildren = TypeUtils.allByType(children, TableRowActions)
  const emptyRowChildren = TypeUtils.allByType(children, TableEmpty)
  const onSelectAll = useCallback(Callbacks.onSelectAllHandler(existingIds, selectedIds, setSelected), [existingIds.join(','), _.join(selectedIds, ','), setSelected])
  const onSelect = useCallback(Callbacks.onSelectHandler(selectedIds, setSelected), [_.join(selectedIds, ','), setSelected])

  return (
    <>
      {(enableToolbar || (enableGlobalActions && enableGlobalActionsPlacement === 'top') || (!isEmptyRecords && enablePagination && placementPagination === 'top')) &&
        <Toolbar>
          <Box sx={{ flex: 1 }}>
            {!!enableToolbar && <ViewSelector selectedIds={selectedIds}>{toolbarChildren}</ViewSelector>}
            {!!enableGlobalActions && enableGlobalActionsPlacement === 'top' &&
              <Actions source={'toolbar'} onSelectAll={onSelectAll} setSelected={setSelected} selectedIds={selectedIds} rows={rows}>
                {tableActionChildren}
              </Actions>
            }
          </Box>
          {!isEmptyRecords && enablePagination && _.includes(['top', 'both'], placementPagination) &&
            <Pagination
              onRowsChange={onRowsChange}
              paginationState={paginationState}
              paginationControls={paginationControls}
            />
          }
        </Toolbar>
      }
      <Loader
        isLoading={HookUtils.getLoadingState([{ isLoading: _.get(paginationState, 'isSearchLoading') }, { isLoading }])}
        _container={{ style: { maxHeight: '450px' } }}
      >
        <TableContainer>
          <Table>
            {(!enableHeaderEmptyRecord || !isEmptyRecords) &&
              <Header
                selectedIds={selectedIds}
                existingIds={existingIds}
                enableRowSelection={enableRowSelection}
                enableRowActions={enableRowActions}
                enableRowActionsHeader={enableRowActionsHeader}
                onSelectAll={onSelectAll}
                paginationControls={paginationControls}
                paginationState={paginationState}
              >
                {headerChildren}
              </Header>
            }
            <Body
              rows={grouped_rows}
              iterators={!disableIterators ? _.get(paginationState, 'result') : undefined}
              setSelected={setSelected}
              selectedIds={selectedIds}
              onSelect={onSelect}
              enableRowSelection={enableRowSelection}
              enableRowActions={enableRowActions}
              enableRowLoading={enableRowLoading}
              enableRowClick={enableRowClick}
              enableRowColor={enableRowColor}
              onRowClick={onRowClick}
            >
              {rowChildren}
              {!!isEmptyRecords && (_.isEmpty(emptyRowChildren) ? (
                <DefaultEmptyRow label={emptyRowLabel} />
              ) : (
                emptyRowChildren.map((emptyChild, key) => (
                  <ViewSelector key={key}>{emptyChild}</ViewSelector>
                ))
              ))}
              {tableRowActionChildren}
            </Body>

          </Table>
        </TableContainer>
      </Loader>
      {
        !isEmptyRecords && enablePagination && _.includes(['bottom', 'both'], placementPagination) &&
        <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <Pagination
            onRowsChange={onRowsChange}
            paginationState={paginationState}
            paginationControls={paginationControls}
          />
        </Toolbar>
      }
      {
        !!enableGlobalActions && enableGlobalActionsPlacement === 'bottom' &&
        <Actions source={'footer'} onSelectAll={onSelectAll} setSelected={setSelected} selectedIds={selectedIds}>
          {tableActionChildren}
        </Actions>
      }
    </>
  );
}

export default React.memo(TableComponent)
export * from './Body'

export {
  TableHeader,
  TableToolbar,
  TableActions,
  TableEmpty,
  TableAction,
  TableDialogAction
}
