import React from 'react'

import { Link as MuiLink, Typography } from '@mui/material'

const Link = ({ sx = {}, label, title, ...rest }) => <>
  <Typography sx={{ fontSize: 13 }} hidden={!title}> {title} </Typography>
  <MuiLink
    target='_blank'
    sx={{ fontWeight: 800, textAlign: 'center', ...sx }}
    {...rest}
  >
    {label}
  </MuiLink>
</>

export default Link
