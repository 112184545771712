import React from 'react'
import AutocompleteComponent, { AutocompleteLabelButtons, AutocompleteFooter } from './component'
import { withField, withModel, withAddress } from '@front/volcanion'
const ModelAutocompleteComponent = React.memo(withModel(AutocompleteComponent))
const AddressAutocompleteComponent = React.memo(withAddress(AutocompleteComponent))
const AutocompleteField = React.memo(withField(AutocompleteComponent, { disableNone: true, isReady: true }))
const ModelAutocompleteField = React.memo(withModel(AutocompleteField))
const AddressAutocompleteField = React.memo(withAddress(AutocompleteField))

export {
  AutocompleteComponent,
  ModelAutocompleteComponent,
  AddressAutocompleteComponent,
  AutocompleteField,
  ModelAutocompleteField,
  AddressAutocompleteField,
  AutocompleteLabelButtons,
  AutocompleteFooter
}
export default AutocompleteComponent
