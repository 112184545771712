import LeafletListener from '../listener'
import LayerGroup from './class'

class LayerListener extends LeafletListener {
  generateLeafletInstance() {
    return new LayerGroup(this, [], this.getOptions())
  }
  hasValidCoordinates() {
    return true
  }
}
export default LayerListener