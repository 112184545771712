import React from 'react'
import DateTimeComponent from './component'
import DateTimeCompactComponent from './Compact'
import { withField } from '@front/volcanion'
const DateTimeField = React.memo(withField(DateTimeComponent))
const DateTimeCompactField = React.memo(withField(DateTimeCompactComponent))

export default DateTimeComponent
export { DateTimeComponent, DateTimeCompactComponent, DateTimeField, DateTimeCompactField }
export * from './Time'
export * from './Date'