import React from 'react'
import _ from 'lodash'
import TextComponent from '../Text'
import { hooks } from '@front/volcanion'

const NumberComponent = (props) => {
  const {
    value: inputValue,
    onChange: onInputChange,
    type,
    valueType,
    minValue,
    maxValue,
    disabled,
    allowNull,
    nullValue,
    precision,
    autoAdjustValue,
    ...custom
  } = props

  const [value, onChange] = hooks.useNumberComponent(inputValue, onInputChange, type || valueType, {
    minValue,
    maxValue,
    disabled,
    allowNull,
    nullValue,
    precision,
    autoAdjustValue
  })

  return (
    <TextComponent
      value={value}
      onChange={onChange}
      type={type || valueType}
      disabled={disabled}
      {...custom}
    />
  )
}

export default React.memo(NumberComponent)
