import React from 'react';
import _ from 'lodash'
import { DialogActions, Button } from '@mui/material';
import { Form, hooks } from '@front/volcanion';

const withInformation = (Component) => ({
  name,
  onClose,
  okLabel = 'OK',
  children,
  ...custom
}) => {
  const [{ extra, state }] = hooks.useDialog(name)
  const title = _.get(extra, 'title')
  const description = _.get(extra, 'description')

  return (
    <Component name={name} title={title} description={description} extra={extra} state={state} onClose={onClose} {...custom}>
      {children}
      <DialogActions>
        <Button onClick={onClose}>{okLabel}</Button>
      </DialogActions>
    </Component>
  );
}
export default withInformation