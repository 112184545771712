import TypeUtils from '@front/volcanion/utils/type'
import ViewSelector from '@front/volcanion/components/ViewSelector'
import React, { useCallback, useState } from 'react'
import ReactDOM from 'react-dom'
import Portal from '../Portal'
import LeafletWrapper from '../wrapper'
import { MapHooks } from '../..'

const ControlBox = React.memo(ViewSelector)

function LayersControl({ children, defaultMinimized, hasMinimize, ...props }) {

  const [isMinimized, setMinimized] = useState(defaultMinimized)
  const controlChildren = TypeUtils.allByType(children, ControlBox)
  const layerChildren = TypeUtils.withoutTypes(children, ControlBox)
  const container = MapHooks.useLayersControlListener(useCallback((instance) => instance?.getContainer(), []))

  return (
    <>
      {layerChildren}
      {
        !!container && ReactDOM.createPortal((
          <Portal {...props} isMinimized={isMinimized} setMinimized={setMinimized} hasMinimize={hasMinimize} {...TypeUtils.getProps(_.head(controlChildren))} >{controlChildren}</Portal>
        ), container)
      }
    </ >
  );
}
export default React.memo(({ children, defaultMinimized, hasMinimize, ...props }) => <LeafletWrapper {...props} listener_type={'control'}><LayersControl hasMinimize={hasMinimize} defaultMinimized={defaultMinimized}>{children}</LayersControl></LeafletWrapper>)

export {
  ControlBox
}

