import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles'
import { Dialog as MuiDialog, DialogActions as MuiDialogActions, DialogContent as MuiDialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import _ from 'lodash'
import TypeUtils from '@front/volcanion/utils/type'
import ViewSelector from '@front/volcanion/components/ViewSelector';
const useStyles = makeStyles(styles)
const DialogActions = React.memo(ViewSelector)
const DialogContent = React.memo(ViewSelector)

const Dialog = ({
  name,
  isOpen = false,
  onClose,
  title,
  description,
  children,
  extra,
  state,
  _title,
  _description,
  ...custom
}) => {
  const contentChildren = TypeUtils.allByType(children, DialogContent)
  const muiContentChildren = TypeUtils.allByType(children, MuiDialogContent)

  const actionChildren = TypeUtils.allByType(children, DialogActions)
  const muiActionChildren = TypeUtils.allByType(children, MuiDialogActions)

  return (
    <MuiDialog fullWidth {...custom} open={isOpen} onClose={onClose}>
      {!!title && <DialogTitle><Typography variant={'h5'} color={'primary'} {..._title}>{title}</Typography></DialogTitle>}
      <MuiDialogContent>
        {!!description && (
          <DialogContentText {..._description}>
            {description}
          </DialogContentText>
        )}
        <ViewSelector {...extra}>{TypeUtils.getChildren(contentChildren)}</ViewSelector>
        {muiContentChildren}
      </MuiDialogContent>
      <MuiDialogActions>
        <ViewSelector {...extra}>{TypeUtils.getChildren(actionChildren)}</ViewSelector>
        {muiActionChildren}
      </MuiDialogActions>
    </MuiDialog>
  );
}
export default React.memo(Dialog)
export {
  DialogActions,
  DialogContent,
}