import React from 'react'
import _ from 'lodash'
import { Rating } from '@mui/material'
import { makeStyles } from '@mui/styles'

import styles from './styles'
const useStyles = makeStyles(styles)

const RatingComponent = (props) => {
  const {
    allowNone,
    noneValue,
    name,
    value,
    onChange,
    disabled,
    emptyValue = '-',
    meta,
    InputProps,
    ...custom
  } = props

  const mergerInputProps = _.merge({}, { disableUnderline: !!disabled }, InputProps)

  return (
    <Rating
      onChange={e => !!onChange && onChange(_.get(e, 'target.value'))}
      value={value || (!!disabled ? emptyValue || '' : '')}
      disabled={disabled}
      fullWidth
      InputProps={mergerInputProps}
      {...custom}
    />
  )
}

export default React.memo(RatingComponent)
