import React from 'react'

import { makeStyles } from '@mui/styles'
import PhoneInput from 'react-phone-input-2'

import stylesJS from './styles'

const useStyles = makeStyles(stylesJS)

const PhoneInputComponent = (props) => {
  const {
    name,
    value,
    onChange,
    disabled,
    meta,
    enableSearch = true,
    options: onlyCountries,
    ...custom
  } = props

  const classes = useStyles(props)
  return (
    <PhoneInput
      onChange={v => !!onChange && onChange(`+${v}`)}
      value={value}
      disabled={disabled}
      enableSearch={enableSearch}
      onlyCountries={onlyCountries}
      dropdownClass={classes.dropdown}
      {...custom}
    />
  )
}

export default React.memo(PhoneInputComponent)
