import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'
import { Box, Button, ButtonGroup } from '@mui/material'
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import Loader from '../../Loader'
const Selected = ({
  anchorRef,
  options,
  variant = 'contained',
  size = 'medium',
  isLoading,
  disabled,
  open,
  setOpen,
  selectedIndex
}) => {

  const handleClick = useCallback(async e => {
    try {
      await options[selectedIndex].onClick()
    }
    catch (err) {
      console.log('Error: ', err)
    }
    finally {
      e.stopPropagation()
    }
  }, [])

  const handleToggle = useCallback(event => {
    setOpen(!open)
    event.stopPropagation()
  }, [])

  const displayMenu = options.length > 1
  const rightButtonColor = variant === 'outlined' ? 'text.button.outlined' : 'text.button.default'
  return (
    <ButtonGroup variant={variant} color='primary' disabled={disabled} ref={anchorRef} size={size}>
      <Button onClick={handleClick}>
        <Loader isLoading={isLoading}>
          <Box fontWeight={600} color={rightButtonColor}>
            {I18n.t(_.get(options[selectedIndex], 'labelKey'))}
          </Box>
        </Loader>
      </Button>
      {!!displayMenu &&
        <Button color='primary' size='small' onClick={handleToggle}  >
          <ArrowDropDownIcon color='icon' />
        </Button>
      }
    </ButtonGroup>
  )
}

export default React.memo(Selected)
