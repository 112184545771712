export default {
  table: {
    empty: "Aucun résultat disponible"
  },
  page: {
    rowPerPage: "Éléments par page",
    displayed_rows: "{{from}}-{{to}} sur {{count}}"
  },
  datetime: {
    disablePast: "Date dans le passé",
    invalidDate: "Date invalide",
    minTime: "Heure dans le passé",
    minDate: "Éditer ce champ pour voir les dates disponibles"
  },
  date: {
    disablePast: "Date dans le passé",
    invalidDate: "Date invalide"
  },
  time: {
    minTime: "Heure dans le passé",
    invalidDate: "Date invalide"
  },
  driver: {
    info: "Informations du chauffeur :"
  },
  vehicle: {
    label: {
      one: "Véhicule :"
    },
    status: {
      available: "Disponible",
      unavailable: "Indisponible",
      in_use: "En cours",
      en_route: "En route",
      reserved: "Réservée"
    },
    info: "Informations du véhicule :"
  },
  order: {
    info: "Informations de la course :"
  },
  dispatch: {
    label: {
      one: "Dispatch :"
    },
    action: "Dispatcher"
  }
}
