import LeafletListener from '../listener'
import Polyline from './class'

class PolylineListener extends LeafletListener {
  generateLeafletInstance() {
    return new Polyline(this, this.getActiveCoordinates(), this.getOptions())
  }
  onPositionChange(prev_positions, new_positions) {
    this.getInstance().setLatLngs(new_positions)
    return super.onPositionChange(prev_positions, new_positions)
  }
}

export default PolylineListener