import React from 'react'
import { hooks } from '@front/volcanion'
const withContainer = Component => props => {
  const MAP_CENTER = hooks.useRelayConstant('MAP_CENTER')
  const coords = _.split(MAP_CENTER, ',')
  const mergedProps = {
    mapCenter: coords
  }
  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
