
import getTheme from './theme'
import getComponents from './components'
import getTypography from './typography'
import buildTheme from './manager'
import colors from './colors'

/* DEPRECATED */
import setFleetizenLayerTheme from './MaterialV3_FL_LAYER'

import {
  dark as MuiThemeV3_DARK,
  light as MuiThemeV3
} from './MaterialV3'


export {
  getTheme,
  getTypography,
  getComponents,
  buildTheme,
  colors,
  /* DEPRECATED */
  MuiThemeV3_DARK,
  MuiThemeV3,
  setFleetizenLayerTheme,

}
