import React from 'react'

import _ from 'lodash'
import { ModelForm, hooks, I18n } from '@front/volcanion'
import { DialogActions, Button } from '@mui/material'

const withModelForm = (Component) => ({
  name,
  onClose,
  allowConfirm = true,
  allowCancel = true,
  submitLabel = I18n.t('action.confirm'),
  cancelLabel = I18n.t('action.return'),
  closeModalOnSubmitSuccess = true,
  formProps: inputFormProps,
  children,
  ...custom
}) => {
  const [{ isOpen, extra, state }] = hooks.useDialog(name)
  const title = _.get(extra, 'title')
  const description = _.get(extra, 'description')
  const extraFormProps = _.get(extra, 'formProps')
  const formName = `${name}_dialog_form` || 'dialog_form'
  const requestSubmit = hooks.useGlobalFormFunction(formName, 'requestSubmit')
  const formProps = {
    name: formName,
    initialState: { isReadOnly: false },
    ...inputFormProps,
    ...extraFormProps,
    onSubmitSuccess: closeModalOnSubmitSuccess ? onClose : null,
  }
  return (
    <ModelForm {...formProps} status={{ isReady: isOpen }}>
      <Component name={name} title={title} description={description} extra={extra} state={state} onClose={onClose} {...custom}>
        {children}
        <DialogActions>
          {!!allowCancel && <Button onClick={onClose}>{cancelLabel}</Button>}
          {!!allowConfirm && <Button onClick={() => requestSubmit()}>{submitLabel}</Button>}
        </DialogActions>
      </Component>
    </ModelForm>
  );
}
export default withModelForm
