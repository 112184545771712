import React from 'react'
import { Icon, Typography } from '@mui/material'

import Button from '@mui/material/Button'

const Action = ({ label, icon, iconProps, labelProps, onClick, controls, table, action, row, rowId, groupIndex, ButtonComponent = Button, ...props }) => {
  return (
    <ButtonComponent
      {...props}
      onClick={() => onClick({ action, row, rowId, groupIndex })}
    >
      {icon && (
        <Icon
          size={'small'}
          color={'primary'}
          baseClassName='material-icons-outlined'
          className={icon}
          sx={{ mr: !!label ? 1 : 0 }}
          {...iconProps}
        >
          {icon}
        </Icon>
      )}
      {!!label && <Typography {...labelProps}>{label}</Typography>}
    </ButtonComponent>
  )
}

export default React.memo(Action)
